import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CheckoutStore } from "@/store/tapwell/checkoutstate";
import { Address, CheckoutModel, ModelState } from "@/models/tapwell/Checkout";
import { CheckoutQuery, UnifaunOnChange } from "@/models/tapwell/Unifaun";
import { ApiService } from "@/services/tapwell/ApiService";
import { Spinner } from "spin.js";
import DatePicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";

@Component({
    components: {
        DatePicker,
    },
})
export default class CustomerInformation extends Vue {
    protected spinner: Spinner;
    protected widget: any;
    private deliveryCheckout: any[] = [];

    protected languages: {
        sv: any;
    };

    get cart() { return CheckoutStore.cart; }

    @Prop()
    protected model!: CheckoutModel;

    private viewmodelInternal!: CheckoutModel;

    protected translations: any;
    protected requestContext: any;
    protected buttonIsPressed: boolean = false;
    protected smsNotification: boolean = false;
    protected isMounted: boolean = false;

    private initialDeliveryDateInternal: Date = new Date();

    constructor() {
        super();
        this.translations = window.__litium.translation;
        this.requestContext = window.__litium.requestContext;
        this.spinner = new Spinner({ scale: 0.5 });
        this.languages = { sv: sv };
        this.checkoutmodel = this.model;
        this.initialDeliveryDate = this.model.selectedDeliveryDate;
        this.checkoutmodel.alternativeAddress = {} as Address;
        this.checkoutmodel.alternativeAddress.country = this.checkoutmodel.defaultCountryId;
    }

    @Watch("cart.cartTotalExclDeliveryCost")
    cartTotalUpdated(newValue: string, oldValue: string) {
        if (newValue !== oldValue) {
            this.updateUnifaun();
        }
    }

    get checkoutmodel(): CheckoutModel {
        return this.viewmodelInternal;
    }

    set checkoutmodel(value: CheckoutModel) {
        this.viewmodelInternal = value;
    }

    get initialDeliveryDate(): Date {
        return new Date(this.initialDeliveryDateInternal);
    }

    set initialDeliveryDate(value: Date) {
        this.initialDeliveryDateInternal = value;
    }

    get showFormErrorMessage(): boolean {
        var hasErrors = false;

        if (this.isMounted) {
            if (this.checkoutmodel.unifaunOnChange && !this.checkoutmodel.unifaunOnChange.valid) {
                return true;
            }

            var observer = this.$refs.observer as any;
            if (observer) {
                for (var prop in observer.errors) {
                    if (Object.prototype.hasOwnProperty.call(observer.errors, prop)) {
                        var error = observer.errors[prop];
                        if (error.length > 0) {
                            hasErrors = true;
                            break;
                        }
                    }
                }
            }
        }

        return hasErrors;
    }

    mounted() {
        this.isMounted = true;
    }

    get formattedSelectedDeliveryDate(): Date {
        return new Date(this.checkoutmodel.selectedDeliveryDate);
    }

    set formattedSelectedDeliveryDate(value: Date) {
        this.checkoutmodel.selectedDeliveryDate = value;
    }

    get lastDisabledDeliveryDate(): Date {
        var d = this.initialDeliveryDate;
        d.setDate(this.initialDeliveryDate.getDate() - 1);
        
        return d;
    }

    get currentLanguage(): string {
        return (document.querySelector("html") as HTMLElement).getAttribute("lang") as string;
    }

    public placeOrder() {
        this.spinner.spin(this.$el as HTMLElement);
        this.buttonIsPressed = true;
        this.checkoutmodel.message = "";
        this.checkoutmodel.modelState = { general: [] as string[] } as ModelState;

        ApiService.checkoutOrder(this.checkoutmodel).then((response) => {
            this.spinner.stop();
            if (response && response.status == 200) {
                window.location.replace(response.data.redirectUrl);
            } else if (response && response.status == 400) {
                var statusText = typeof (response.statusText) === "string" ? JSON.parse(response.statusText) : response.statusText;
                this.checkoutmodel.message = statusText.message;
                this.checkoutmodel.modelState = statusText.modelState;
                // Error handling
            }
        });
    }

    public changeDeliveryDate(selectedDate: Date) {
        this.formattedSelectedDeliveryDate = selectedDate;
        this.updateUnifaun();
    }

    public updateUnifaun() {
        if (window.UnifaunCheckout) {
            if (this.widget == null) {
                this.widget = window.UnifaunCheckout.createAt("#" + this.checkoutmodel.requestId, {
                    language: this.currentLanguage,
                    resultCallback: this.widgetChanged.bind(this)
                });
                this.widget.installCSS();
            }

            var tozipcode = this.checkoutmodel.alternateDeliveryAddress ? this.checkoutmodel.alternativeAddress.zipCode : this.checkoutmodel.deliveryAddress.zipCode;
            var addressCountry = this.checkoutmodel.alternateDeliveryAddress ? this.checkoutmodel.alternativeAddress.country : this.checkoutmodel.deliveryAddress.country;
            var country = this.checkoutmodel.countries.find(country => country.id === addressCountry);
            if (country == null || tozipcode == null) {
                this.widget.disable();
            } else {

                const model: CheckoutQuery = {
                    currency: country.currency,
                    language: this.currentLanguage,
                    tocountry: addressCountry,
                    totalCartPrice: this.cart.cartTotalExclDeliveryCost,
                    tozipcode: tozipcode,
                    weight: this.cart.cartTotalWeight,
                    deliveryDate: this.checkoutmodel.selectedDeliveryDate,
                    organizationId: this.checkoutmodel.organizationId,
                    channelSystemId: this.requestContext.channelSystemId
                };

                let self = this;
                self.widget.disable();
                ApiService.getDeliveryCheckouts(model).then((response) => {
                    if (response && response.data) {
                        return JSON.parse(response.data);
                    }
                }).then(function (data) {

                    if (self.deliveryCheckout.length > 0) {
                        self.deliveryCheckout = [];
                    }

                    if (data && data.options && data.options.length > 0) {
                        for (var i = 0; i < data.options.length; i++) {
                            if (data.options[i].genericKeyValues && data.options[i].genericKeyValues.length > 0) {
                                self.deliveryCheckout.push({ 'deliveryId': data.options[i].id, 'jeevesDeliveryId': data.options[i].genericKeyValues[0].value });
                            }
                        }
                    }
                    self.widget.updateList(data);
                }).then(function () {
                    self.widget.enable();
                });
            }
        }
    }

    public widgetChanged(result: UnifaunOnChange) {

        this.checkoutmodel.unifaunOnChange = result;

        var selectedUnifaunOption = (result.optionIds && result.optionIds.length > 0) ? result.optionIds[0] : "";

        if (selectedUnifaunOption != "" && this.deliveryCheckout != undefined) {

            var currentDictionaryValue = this.deliveryCheckout.find(x => x.deliveryId === selectedUnifaunOption);
            if(currentDictionaryValue!=undefined && currentDictionaryValue!=null){
            this.checkoutmodel.jeevesDeliveryId = currentDictionaryValue.jeevesDeliveryId;
            }
            else
            {
                this.checkoutmodel.jeevesDeliveryId="";
            }
        }

        var smsaddon = result.selectedAddons.find(x => x.id === "FCNOTIFYSMS");
        if (smsaddon != null) {
            var sms = smsaddon.fields.find(x => x.id === "sms");
            if (sms != null) {
                this.smsNotification = sms.value.length >= 10
                this.checkoutmodel.notificationPhone = sms.value;
            }
        }

        var emailaddon = result.selectedAddons.find(x => x.id === "FCNOTIFYEMAIL");
        if (emailaddon != null) {
            var email = emailaddon.fields.find(x => x.id === "email");
            if (email != null) {
                this.checkoutmodel.notificationEmail = email.value;
            }
        }

        var phonefield = result.fields.find(x => x.id === "FCRECEIVERPHONE");
        if (phonefield != null) {
            this.smsNotification = phonefield.value.length >= 10
            this.checkoutmodel.notificationPhone = phonefield.value;
        }

        this.spinner.spin(this.$el as HTMLElement);
        this.buttonIsPressed = true;
        ApiService.setDelivery(this.checkoutmodel).then((response) => {
            if (response && response.data) {
                CheckoutStore.reloadState().then(() => { /* nothing */ });
            }
            this.spinner.stop();
            this.buttonIsPressed = false;
        });
    }
}
