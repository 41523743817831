import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import "../../models/Window";
import { orderCatalog } from "@/models/bricmate/orderCatalog";

function errorResponse(e: any): Promise<AxiosResponse<any>> {
    const response: AxiosResponse<boolean> = {
        data: false,
        status: e.response.status,
        statusText: e.response.data,
        headers: {},
        request: {},
        config: null as unknown as AxiosRequestConfig,
    };
    return Promise.resolve(response);
}

// For some reason if I import the ApiService from ./ApiService.ts the styling disappears and I don't know why
export const ApiService2 =
{
    async orderCatalog(model: orderCatalog): Promise<AxiosResponse<string[]>> {
        try {
            return await axios.post("/api/bricmateb2ccatalogorder/catalog", model, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    "RequestVerificationToken": window.localStorage.getItem("requestVerificationToken"),
                    'content-type': 'application/json',
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },

};
