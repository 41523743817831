import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Checkbox } from "../";
import { MultiSelectOption } from "./MultiSelectOption";

@Component({
    components: {
        "v-checkbox": Checkbox,
    },
})
export default class MultiSelect extends Vue {
    @Prop({ default: "Select" })
    label!: string;

    @Prop()
    value!: Array<string>;

    @Prop()
    options!: Array<MultiSelectOption>;

    @Prop({ default: false })
    loading!: boolean;

    translations: any;

    isOpen: boolean = false;
    count: number = 0;
    caption: string = "";

    constructor() {
        super();

        this.translations = window.__litium.translation;

        this.count = this.value.length;
    }

    updateCount() {
        const updated = [...this.value];
        this.count = updated.length;
    }

    created() {
        this.caption = this.translations["imagebank.selection.none"];
    }

    @Watch("count")
    setLabel() {
        if (this.count <= 0) {
            this.caption = this.translations["imagebank.selection.none"];
        } else if (this.count === 1) {
            this.caption = `1 ${this.translations["imagebank.selection.one"]}`;
        } else {
            this.caption = `${this.count} ${this.translations["imagebank.selection.many"]}`;
        }
    }

    @Emit("input")
    check(id: string, checked: boolean) {
        const updated = [...this.value];

        if (checked) {
            updated.push(id);
        } else {
            updated.splice(updated.indexOf(id), 1);
        }

        this.count = updated.length;

        return updated;
    }
}

