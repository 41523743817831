import { Component, Prop, Vue } from "vue-property-decorator";
import { CategoryModel } from "@/models/tapwell/Category";

@Component
export default class CategoryBanner extends Vue {

    @Prop()
    protected viewmodel!: CategoryModel;

    constructor() {
        super();
    }

    get headline(): string {
        return this.viewmodel.headline;
    }

    get desktopImageUrl(): string {
        return this.viewmodel.desktopImageUrl;
    }

    get mobileImageUrl(): string {
        return this.viewmodel.mobileImageUrl;
    }
}
