import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import VueGtm from "gtm-vue";
import UniqueId from "vue-unique-id";
import moment from "moment";
import { ValidationProvider, localize, ValidationObserver } from "vee-validate";
import "@/localization/veelocalize";
import store from "@/store/tapwell/index";
import "spin.js/spin.css";
import "@/styles/tapwell/main.scss";
import "@/scripts/tapwell/application.js";
import Category from "@/components/tapwell/Category/Category.vue";
import MiniCart from "@/components/tapwell/MiniCart/MiniCart.vue";
import ProductDetail from "@/components/tapwell/Product/ProductDetail.vue";
import ProductDetailHaven from "@/components/tapwell/Product/ProductDetailHaven.vue";
import ProductUpSell from "@/components/tapwell/Product/ProductUpSell.vue";
import ProductAccessoryList from "@/components/tapwell/Product/ProductAccessoryList.vue";
import ReorderButton from "@/components/tapwell/Order/ReorderButton.vue";
import Checkout from "@/components/tapwell/Checkout/Checkout.vue";
import CheckoutB2c from "@/components/tapwell/Checkout/CheckoutB2C.vue";
import OrderConfirmationB2c from "@/components/tapwell/Checkout/OrderConfirmationB2C.vue";
import ParallaxBlock from "@/components/tapwell/Blocks/ParallaxBlock.vue";
import ProductDetailB2c from "@/components/tapwell/Product/ProductDetailB2C.vue";
import SearchResultB2c from "@/components/tapwell/Search/SearchResultB2C.vue";
import SearchB2c from "@/components/tapwell/Search/SearchB2C.vue";
import SparePartShop from "@/components/tapwell/SparePartShop/SparePartShop.vue";
import CustomerServiceReport from "@/components/tapwell/CustomerSupport/CustomerServiceReport.vue";
import SelectOrganization from "@/components/tapwell/SelectOrganization/SelectOrganization.vue";
import CatalogForm from "@/components/bricmate/CatalogForm";
import NewsletterSignup from "@/components/tapwell/NewsletterSignup/NewsletterSignup.vue";
import NewsletterBanner from "@/components/tapwell/NewsletterSignup/NewsletterBanner.vue";
import CountryPicker from "@/components/tapwell/CountryPicker/CountryPicker.vue";
import ShowerConfiguratorStandalone from "@/components/tapwell/Configurator/ShowerConfiguratorStandalone.vue";
import VueScrollTo from "vue-scrollto";
import VueLazyload from "vue-lazyload";
import CookieLaw from "vue-cookie-law";
import ImageBank from "@/imagebank/ImageBank.vue";

Vue.use(UniqueId);
Vue.use(VueScrollTo);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("CookieLaw", CookieLaw);
Vue.config.productionTip = false;

const gtmid = (document.querySelector("#app") as HTMLElement).getAttribute("data-gtmid") as string;

Vue.use(VueLazyload, {
    loading: "/lazy-loader.svg",
    throttleWait: 10,
    observer: true,
    preLoad: 5
});

Vue.use(VueGtm, {
    id: gtmid,
    enabled: gtmid != null && gtmid !== "",
    debug: process.env.NODE_ENV !== "production",
});

new Vue({
    components: {
        Category,
        MiniCart,
        ProductDetail,
        ProductUpSell,
        ProductAccessoryList,
        ReorderButton,
        Checkout,
        ParallaxBlock,
        ProductDetailB2c,
        SearchB2c,
        SparePartShop,
        CheckoutB2c,
        OrderConfirmationB2c,
        CustomerServiceReport,
        SelectOrganization,
        SearchResultB2c,
        CatalogForm,
        NewsletterSignup,
        NewsletterBanner,
        CountryPicker,
        ShowerConfiguratorStandalone,
        ProductDetailHaven,
        ImageBank,
    },
    store,
    created() {
        const culture = (document.querySelector("html") as HTMLElement).getAttribute("lang") as string;
        localize(culture);
        moment.locale(culture);
    },
}).$mount("#app");
