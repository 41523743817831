import { getStoreBuilder, BareActionContext } from "vuex-typex";
import { RootState } from "./index";
import { Guid } from "guid-typescript";
import { Product } from "@/models/tapwell/Product";

export interface ProductState {
    currentCategoryId: Guid;
    products: Product[];
    searchresult: Product[];
    resetInfiniteLoaderStatus: boolean;
    searchTerm: string;
}

const initialState: ProductState = {
    currentCategoryId: Guid.createEmpty(),
    products: [],
    searchresult: [],
    resetInfiniteLoaderStatus: false,
    searchTerm: ''
};

const store = getStoreBuilder<RootState>().module("product", initialState);

// private getters
function getStoreCategoryId(state: ProductState): Guid {
    return state.currentCategoryId;
}

function getStoreProducts(state: ProductState): Product[] {
    return state.products;
}

function getStoreResetInfiniteLoader(state: ProductState): boolean {
    return state.resetInfiniteLoaderStatus;
}

function getStoreSearchResult(state: ProductState): Product[] {
    return state.searchresult;
}

function getSearchTerm(state: ProductState): string {
    return state.searchTerm;
}

// getters
const getStateCategoryId = store.read(getStoreCategoryId, "getStoreCategoryId");
const getStateProducts = store.read(getStoreProducts, "getStoreProducts");
const getStateResetInfiniteLoader = store.read(getStoreResetInfiniteLoader, "getStoreResetInfiniteLoader");
const getStateSearchResult = store.read(getStoreSearchResult, "getStoreSearchResult");
const getStateSearchTerm = store.read(getSearchTerm,"getSearchTerm")

// mutations:
function mutateCurrentCategoryId(state: ProductState, currentCategoryId: Guid): Promise<void> {
    return new Promise((resolve, reject) => {
        state.currentCategoryId = currentCategoryId;
        resolve();
    });
}

function mutateProducts(state: ProductState, products: Product[]): Promise<void> {
    return new Promise((resolve, reject) => {
        state.products = products;
        resolve();
    });
}

function mutateInfiniteLoaderStatus(state: ProductState, reset: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
        state.resetInfiniteLoaderStatus = reset;
        resolve();
    });
}

function mutateSearchResult(state: ProductState, products: Product[]): Promise<void> {
    return new Promise((resolve, reject) => {
        state.searchresult = products;
        resolve();
    });
}

function mutateSearchTerm(state: ProductState, searchTerm: string): Promise<void> {
    return new Promise((resolve, reject) => {
        state.searchTerm = searchTerm;
        resolve();
    });
}

// actions
async function saveCurrentCategoryId(context: BareActionContext<ProductState, RootState>, currentCategoryId: Guid): Promise<void> {
    return await mutateCurrentCategoryId(context.state, currentCategoryId);
}

async function saveProducts(context: BareActionContext<ProductState, RootState>, products: Product[]): Promise<void> {
    return await mutateProducts(context.state, products);
}

async function saveInfiniteLoaderStatus(context: BareActionContext<ProductState, RootState>, reset: boolean): Promise<void> {
    return await mutateInfiniteLoaderStatus(context.state, reset);
}

async function saveSearchResult(context: BareActionContext<ProductState, RootState>, products: Product[]): Promise<void> {
    return await mutateSearchResult(context.state, products);
}

async function  saveSearchTerm(context:BareActionContext<ProductState,RootState>, searchTerm:string):Promise<void> {
    return await mutateSearchTerm(context.state, searchTerm)
}
// export store
export const ProductStore = {
    saveCurrentCategoryId: store.dispatch(saveCurrentCategoryId, "saveCurrentCategoryId"),
    get currentCategoryId() { return getStateCategoryId(); },

    saveProducts: store.dispatch(saveProducts, "saveProducts"),
    get products() { return getStateProducts(); },

    saveInfiniteLoaderStatus: store.dispatch(saveInfiniteLoaderStatus, "saveInfiniteLoaderStatus"),
    get resetInfiniteLoader() { return getStateResetInfiniteLoader(); },

    saveSearchResult: store.dispatch(saveSearchResult, "saveSearchResult"),
    get searchresult() { return getStateSearchResult(); },

    saveSearchTerm: store.dispatch(saveSearchTerm,"saveSearchTerm"),
    get searchTerm(){return getStateSearchTerm();},
};
