import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DatePicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
import { CustomerServiceModel, CustomerResponseModel } from "@/models/tapwell/CustomerService";
import { Spinner } from "spin.js";
import { ApiService } from "@/services/tapwell/ApiService";
@Component({
    components: {
        DatePicker,
    },
})
export default class CustomerServiceReport extends Vue {
    public translations: any;
    @Prop()
    protected viewmodel!: CustomerServiceModel;
    private viewmodelInternal!: CustomerServiceModel;
    protected spinner: Spinner;
    protected selectedFromDate: Date;
    protected selectedToDate: Date;
    get customerservicemodel(): CustomerServiceModel {
        return this.viewmodelInternal;
    }

    set customerservicemodel(value: CustomerServiceModel) {
        this.viewmodelInternal = value;
    }

    protected isMounted: boolean = false;

    protected languages: {
        sv: any;
    };
    constructor() {
        super();    
        this.customerservicemodel = this.viewmodel;
        this.languages = { sv: sv };
        this.spinner = new Spinner({ scale: 0.5 });
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth()-1, 2);
        this.selectedFromDate= firstDay
        this.selectedToDate= new Date();
        this.translations = window.__litium.translation;
        
    }

    // mounted() {
    //     this.isMounted = true;
    // }

    get formattedSelectedfromDate(): Date {
        return new Date(this.selectedFromDate);
    }
    set formattedSelectedfromDate(value: Date) {    
        this.selectedFromDate=value;
    }    
    get formattedSelectedtoDate(): Date {
        return new Date(this.selectedToDate);
    }
    set formattedSelectedtoDate(value: Date) {        
        this.selectedToDate=value;
    }  
    



    get currentLanguage(): string {
        return (document.querySelector("html") as HTMLElement).getAttribute("lang") as string;
    }
    public changeFromDate(selectedDate: Date) {
        this.formattedSelectedfromDate = selectedDate;
    }
    public changeToDate(selectedDate: Date) {
        this.formattedSelectedtoDate = selectedDate;
    }
    
    public happyCustomerCall(index: number): void {
        this.spinner.spin(this.$el as HTMLElement);
        var clickedItem = this.customerservicemodel.customerServiceItems[index];       

            const payload: CustomerResponseModel = {
                buttonText: clickedItem.buttonText,
                isHappy:true,
                dataIdentifier:this.customerservicemodel.dataIdentifier
            };
        this.saveCustomerResponce(payload);
       
    }
    public sadCustomerCall(index: number): void {
        this.spinner.spin(this.$el as HTMLElement);
        var clickedItem = this.customerservicemodel.customerServiceItems[index];

        const payload: CustomerResponseModel = {
            buttonText: clickedItem.buttonText,
            isHappy:false,
            dataIdentifier:this.customerservicemodel.dataIdentifier
        };
        this.saveCustomerResponce(payload);
    }


    public saveCustomerResponce(payload: CustomerResponseModel): void {

    ApiService.saveCustomercalls(payload).then((response) => {
        if (response.status == 200) {
            var self = this;
            setTimeout(function () {                
                self.spinner.stop();
            }, 1000);
            
        }
        else if (response.status == 400) {
            this.spinner.stop();

        }
        else {
            this.spinner.stop();
        }
    });
    }


    public getCustomercallsReport(): void {
        this.spinner.spin(this.$el as HTMLElement);
        

        ApiService.getCustomercallsReport(this.customerservicemodel.dataIdentifier,this.selectedFromDate,this.selectedToDate).then((response) => {
            if (response.status == 200) {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'report.csv');
                document.body.appendChild(link);
                link.click();

                this.spinner.stop();

            }
            else if (response.status == 400) {
                this.spinner.stop();
    
            }
            else {
                this.spinner.stop();
            }
        });
           
      

    }
}
