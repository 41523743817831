import { getStoreBuilder } from "vuex-typex";
import { RootState } from "@/store/tapwell";
import { ImageBankImageModel } from "@/imagebank/model";

export interface ImageBankState {
    selectedImages: ImageBankImageModel[];
}

const initialState: ImageBankState = {
    selectedImages: new Array<ImageBankImageModel>(),
};

const store = getStoreBuilder<RootState>().module("imagebank", initialState);

// getters
const allSelectedImagesGetter = store.read(allSelectedImages, "allSelectedImages");

// mutations
function addSelectedImage(state: ImageBankState, payload: ImageBankImageModel) {
    if (state.selectedImages.find(x => x.articleNumber === payload.articleNumber)) return;

    const before = [...state.selectedImages];
    const after = [...before, payload];

    state.selectedImages = [...after];
}

function removeSelectedImage(state: ImageBankState, payload: ImageBankImageModel) {
    const before = [...state.selectedImages];
    const after = [...before.filter(x => x.articleNumber !== payload.articleNumber)];

    state.selectedImages = [...after];
}

function clearAllSelected(state: ImageBankState) {
    state.selectedImages = [];
}

// actions
export const imageBankStore = {
    get selectedImages(): ImageBankImageModel[] {
        return allSelectedImagesGetter();
    },
    addSelectedImage: store.commit(addSelectedImage),
    removeSelectedImage: store.commit(removeSelectedImage),
    clearAllSelected: store.commit(clearAllSelected),
};

// functions
function allSelectedImages(state) {
    return state.selectedImages;
}
