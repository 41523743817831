import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CategoryNavigationItem, CategoryModel } from "@/models/tapwell/Category";
import { Product, SearchProductsResult } from "@/models/tapwell/Product";
import ProductList from "./ProductList.vue";
import CategoryNavigation from "./CategoryNavigation.vue";
import CategoryBanner from "./CategoryBanner.vue";
import ProductSearch from "./ProductSearch.vue";
import { Guid } from "guid-typescript";
import { ApiService } from "@/services/tapwell/ApiService";
import { ProductStore } from "@/store/tapwell/productstate";
import { TapwellGroupWebsite } from "@/models/TapwellGroupWebsite";

var VueScrollTo = require("vue-scrollto");

@Component({
    components: {
        CategoryBanner,
        CategoryNavigation,
        ProductList,
        ProductSearch,
    },
})
export default class Category extends Vue {
    public translations: any;

    @Prop()
    protected viewmodel!: CategoryModel;

    private viewmodelInternal!: CategoryModel;

    private pageIndex: number = 2;

    public showLoadMoreButton: boolean = true;

    private searchmode: boolean = false;

    @Prop()
    protected searchTerm!: string;

    private loading: boolean = false;

    private noResult: boolean = false;

    private requestContext: any;

    private searchTermInternal: string = "";

    private timeout: any;

    constructor() {
        super();
        this.categorymodel = this.viewmodel;
        this.translations = window.__litium.translation;
        this.requestContext = window.__litium.requestContext;
    }

    get categorymodel(): CategoryModel {
        return this.viewmodelInternal;
    }

    set categorymodel(value: CategoryModel) {
        this.viewmodelInternal = value;
    }

    get productlist(): Product[] {
        return ProductStore.products;
    }

    get categorynavigation(): CategoryNavigationItem[] {
        return this.categorymodel.categoryNavigation;
    }

    get pagesize(): number {
        return this.requestContext.pageSize;
    }

    set searchterminternal(value: string) {
        this.searchTermInternal = value;
    }

    get searchterminternal(): string {
        return this.searchTermInternal;
    }

    @Watch("searchterminternal")
    public termsChanged(newTerms: string, oldValue: string) {
        var isHaven = window.__currentWebsite === TapwellGroupWebsite.HavenB2B;
        this.noResult = false;
        this.pageIndex = 1;
        if (this.loading) {
            return;
        }

        if (window.dataLayer) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                window.dataLayer.push({
                    event: "search",
                    query: newTerms,
                });
            }, 2000);
        }

        this.loading = true;
        ApiService.searchProducts(newTerms, this.pageIndex, isHaven).then(response => {
            if (response && response.data) {
                this.handlePageSearch(response.data);
            }
        });
    }

    public handlePageSearch(data: SearchProductsResult) {
        if (this.searchterminternal === data.term) {
            ProductStore.saveProducts(ProductStore.products.concat(data.products));
            this.showLoadMoreButton = true;
        }
        if (data.products.length === 0) {
            this.showLoadMoreButton = false;
            this.noResult = true;
        }
        if (data.products.length < this.pagesize) {
            this.showLoadMoreButton = false;
        }

        this.loading = false;
    }

    public loadMoreProducts() {
        var isHaven = window.__currentWebsite === TapwellGroupWebsite.HavenB2B;
        if (this.loading) {
            return;
        }

        if (this.searchmode && this.searchterminternal) {
            this.loading = true;
            ApiService.searchProducts(this.searchterminternal, this.pageIndex, isHaven).then(response => {
                if (response && response.data) {
                    this.handlePageSearch(response.data);
                    this.pageIndex += 1;
                }
            });
        } else if (!this.searchmode) {
            this.loading = true;
            ApiService.getProducts(this.categorymodel.systemId, this.pageIndex, true, "sort_order_field", false, isHaven)
                .then(response => {
                    if (response && response.data) {
                        ProductStore.saveProducts(ProductStore.products.concat(response.data));
                        this.pageIndex += 1;
                        this.noResult = false;

                        var scrollPos = window.scrollY || document.getElementsByTagName("html")[0].scrollTop;
                        VueScrollTo.scrollTo("html", 500, {
                            offset: scrollPos,
                        });

                        if (response.data.length < this.pagesize) {
                            this.showLoadMoreButton = false;
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    public selectCategory(categorySystemId: Guid): void {
        this.showLoadMoreButton = false;
        this.loading = true;
        var searchInput = <HTMLInputElement>document.querySelector(".product-search__input");
        var searchButton = <HTMLButtonElement>document.querySelector("#product_search_button");

        var isHaven = window.__currentWebsite === TapwellGroupWebsite.HavenB2B;

        searchInput.disabled = true;
        searchButton.disabled = true;
        ApiService.getCategory(categorySystemId, true, isHaven)
            .then(response => {
                if (response && response.data) {
                    this.pageIndex = 2;
                    this.categorymodel = response.data;
                    this.searchmode = false;
                    this.searchterminternal = "";
                    this.showLoadMoreButton = true;
                    if (this.categorymodel.products.length < this.pagesize) {
                        this.showLoadMoreButton = false;
                    }
                    ProductStore.saveProducts(this.categorymodel.products);
                } else {
                    this.noResult = true;
                }
            })
            .finally(() => {
                this.loading = false;
                searchInput.disabled = false;
                searchButton.disabled = false;
            });
    }

    public performSearch(term: string): void {
        this.loading = false;
        ProductStore.saveProducts([] as Product[]);

        if (term.length <= 1) {
            this.searchmode = false;
            this.showLoadMoreButton = false;
            return;
        }

        this.searchmode = true;
        var currentlocation = location.pathname + "?q=" + term;
        window.history.pushState(null, "", currentlocation);
        this.searchterminternal = term;
        this.pageIndex = 1;
    }

    public created() {
        ProductStore.saveCurrentCategoryId(this.viewmodel.systemId);
        ProductStore.saveProducts(this.viewmodel.products);
        if (this.viewmodel.products.length < this.pagesize) {
            this.showLoadMoreButton = false;
        }
    }
}
