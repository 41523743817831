import { Component, Prop } from "vue-property-decorator";
import CartView from './CartView';
@Component
export default class CartViewB2C extends CartView {
    @Prop()
    private hasCampaignVoucherEnabled!: boolean;

    constructor() {
        super();
    }
}
