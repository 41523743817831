import { Component, Prop, Watch } from "vue-property-decorator";
import ProductDetail from "./ProductDetail";
import ProductTileB2c from "./ProductTileB2C.vue";
import ShowerConfigurator from "../Configurator/ShowerConfigurator.vue";
import { Product, Variant, SeriesCategory, SocialLinks } from "@/models/tapwell/Product";
import InfiniteLoading from "vue-infinite-loading";
import { ApiService } from "@/services/tapwell/ApiService";
import { ProductStore } from"@/store/tapwell/productstate";
import { Guid } from "guid-typescript";
import queryString from "query-string";
import ProductInspirationalImages from "./ProductInspirationalImages.vue"
import {toggleBodyClassName} from "@/scripts/shared/sharedScripts.js";

@Component({
    components : {
        ProductTileB2c,
        InfiniteLoading,
        ShowerConfigurator,
        ProductInspirationalImages
    },
})
export default class ProductDetailB2C extends ProductDetail {

    @Prop()
    public categoryproducts!: Product[];

    @Prop()
    public categoryid!: Guid;

    @Prop()
    public categoryheadline!: string;

    @Prop()
    public siblingseriescategories!: SeriesCategory[];

    @Prop()
    public sociallinks!: SocialLinks;

    @Prop()
    public showerconfiguratorpdfurl!: string;

    private pageIndex: number = 2;

    private productInternal!: Product;

    protected visibleConfigurator: boolean = false;

    protected reopenShowerConfigurator: boolean = false;
    public selectedTonitonClass: string = 'product-page__banner';
    public colorValue:string="";

    constructor() {
        super();
        this.currentproduct = this.product;
        this.selectedVariant = this.product.variants[0];
        this.colorValue=this.selectedVariant.colorFieldValue;
    }
     mounted() {
        this.getTonitonCssClassForCurrentProduct();
    }

    get productlist(): Product[] {
        return ProductStore.products;
    }

    get currentproduct(): Product {
        return this.productInternal;
    }

    set currentproduct(value: Product){
        this.productInternal = value;
    }

    get shopenabled(): boolean {
        if(this.requestContext.enableEcommerce==='on')
        {
            return true;
        }

        if (this.requestContext.enableEcommerce==='sparePartOnly' && this.currentproduct.isSparePart) {
            return true;
        }
                
        if(this.selectedVariant.outletProduct){
            return true;
        }

        return false;
    }

    get isDiscontinued() : boolean {
        
        if(this.currentproduct.isDiscontinued) {
            return true;
        }
        return false;
        
    }

    get showprice(): boolean {
        return !this.currentproduct.hidePrice && this.requestContext.showPrices
    }

    get visibleVariants(): Variant[] {
        let filteredVariants = this.currentproduct.variants.filter(x => !x.hideFromVariantSelector);
        const variantIndex = filteredVariants.findIndex((variant) => variant.id === this.selectedVariant.id);
        if (variantIndex >-1) {
            return filteredVariants;
        }
        return [] as Variant[];
    }

    get image(): string {
        if (this.selectedVariant.images && this.selectedVariant.images.length > 0) {
            return this.selectedVariant.images[0];
        }
        return "";
    }

    @Watch("selectedVariant")
    public getTonitonCssClassForCurrentProduct(){        
            if (this.currentproduct.isTonITon) {
            switch(this.selectedVariant.colorFieldValue)
            {
                case 'Blue': 
                    this.selectedTonitonClass = "product-page__banner product-page__banner__toniton-blue";
                    break;
                case 'Yellow': 
                    this.selectedTonitonClass =  "product-page__banner product-page__banner__toniton-yellow";
                    break;
                case 'Creme': 
                    this.selectedTonitonClass =  "product-page__banner product-page__banner__toniton-creme";
                    break;
                case 'Peach': 
                    this.selectedTonitonClass =  "product-page__banner product-page__banner__toniton-peach";
                    break;
                case 'Black': 
                    this.selectedTonitonClass =  "product-page__banner product-page__banner__toniton-black";
                    break;
                case 'Green': 
                    this.selectedTonitonClass =  "product-page__banner product-page__banner__toniton-green";
                    break;
                default: 
                    this.selectedTonitonClass =  "product-page__banner"
            }
        }
    }

    public infiniteHandler($state: any): void {
        ApiService.getProducts(this.categoryid, this.pageIndex, false, "category_sort_order", true).then((response) => {
            if (response && response.data) {
                ProductStore.saveProducts(ProductStore.products.concat(response.data));
                this.pageIndex += 1;
                $state.loaded();

                if (response.data.length === 0) {
                    $state.complete();
                }
            }
        });
    }

    public selectVariant(product: Product, variant: Variant): void {
        window.history.pushState(null, "", variant.url);
        document.title = variant.name;
        this.currentproduct = product;
        this.selectedVariant = variant;
        this.colorValue=this.selectedVariant.colorFieldValue;
        this.trackPageView(this.selectedVariant, false);
        var currentlocation = location.pathname + location.search;

        if (currentlocation !== variant.url){
            this.trackPageView(variant, true);
        }

        this.getProductPriceAndStock(variant, 0);
    }
    
    public selectVariantFromQueryString(){
        var query = queryString.parse(location.search);
        if (query.v) {
            const variantIndex = this.currentproduct.variants.findIndex((variant) => variant.urlName === query.v);
            if (variantIndex > -1) {
                let variant = this.currentproduct.variants[variantIndex];
                this.selectedVariant = variant;
                this.colorValue=this.selectedVariant.colorFieldValue;
                document.title = variant.name;
            }
            else
            {
                this.selectedVariant = this.product.variants[0];
            }
        }
    }



    public created() {
        this.selectVariantFromQueryString();

        ProductStore.saveCurrentCategoryId(this.categoryid);
        ProductStore.saveProducts(this.categoryproducts);
    }

    public openPdf(){
        var token = window.localStorage.getItem("requestVerificationToken");
        var verificationToken='';
        if(token!=null){
            verificationToken = token.split(':')[0];
        }

        if(verificationToken!=''){
            var pdfUrl = this.selectedVariant.generatePdfUrl+"&token="+verificationToken;
            window.open(pdfUrl,"_blank");
        }
    }

    handleVisibleConfigurator(){
        this.visibleConfigurator = !this.visibleConfigurator
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        toggleBodyClassName('shower-config--open');
        toggleBodyClassName('mobile-prevent-scroll');

    }

    public openShowerConfiguratorPdf(){
        var token = window.localStorage.getItem("requestVerificationToken");
        var verificationToken='';
        if(token!=null){
            verificationToken = token.split(':')[0];
        }

        if(verificationToken!=''){
            var pdfUrl = this.showerconfiguratorpdfurl + this.selectedVariant.id +"&pageSystemId="+this.selectedVariant.configuratorPageSystemId +"&token=" + verificationToken;
            window.open(pdfUrl,"_blank");
        }
    }

    closeShowerConfigurator(){
        this.visibleConfigurator = !this.visibleConfigurator
        toggleBodyClassName('shower-config--open');
        toggleBodyClassName('mobile-prevent-scroll');
    }

    public selectVariantFromProductTile(product: Product, variant: Variant){
        this.visibleConfigurator = false;    
        if(document.body.classList.contains('shower-config--open')){
            toggleBodyClassName('shower-config--open');
            toggleBodyClassName('mobile-prevent-scroll');
        }    
        this.selectVariant(product, variant);
    }

    saveShowerConfigurator(variantId: string){
        const variant = this.currentproduct.variants.find(x => x.id == variantId)
        if (variant) {
            this.selectVariant(this.currentproduct, variant);
        }
        this.visibleConfigurator = !this.visibleConfigurator
        toggleBodyClassName('shower-config--open');
        toggleBodyClassName('mobile-prevent-scroll');
        const pdfBtn = document.getElementsByClassName('btn__config-fetch-pdf')[0];
        pdfBtn.classList.add('btn__config-fetch-pdf--saved-variant');
    }

    openShowerConfigFromProduct(product,variant){
        
        this.selectVariant(product, variant);
        
        if(this.visibleConfigurator){
            this.reopenShowerConfigurator = !this.reopenShowerConfigurator;
        }
        
        this.visibleConfigurator = true;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        if(!document.body.classList.contains('shower-config--open')){
            toggleBodyClassName('shower-config--open');
            toggleBodyClassName('mobile-prevent-scroll');
        }
    }

}