import { Component, Vue, Prop } from "vue-property-decorator";
import SearchPanel from "./SearchPanel.vue";
import SparePartTile from "./SparePartTile.vue";
import SelectProductTile from "./SelectProductTile.vue";
import { SearchSparePartResult, Product } from "@/models/tapwell/Product";
import { ApiService } from "@/services/tapwell/ApiService";
import { SparePartShopStore } from "@/store/tapwell/sparepartshopstate";
import { Guid } from "guid-typescript";
import { SparePartFilter,UnfilteredSparePartsForSelectedVariant } from "@/models/tapwell/SparePartFilter";
import queryString from "query-string";
@Component({
    components : {
        SearchPanel,
        SparePartTile,
        SelectProductTile
    },
})
export default class SparePartShop extends Vue {
    public translations: any;
   
    private products: Product[] = [];
    private spareParts: Product[] = [];
    private productSpareParts: Product[] = [];
    private selectedProductName!: string;
    private sparePartsTypesFilter: any = [];
    private commonProblemsFilter: any = [];
    private selectedFilterSparePartByType: Guid = Guid.createEmpty();
    private selectedProductNameInternal: string='';
    private showSparePartTile: boolean = false;
    private noSearchResults: boolean = false;
    private queryStringLoad: boolean = true;
    private selectedFilterText: string='';
    private sparePartFilter:SparePartFilter =
                    { 
                        searchSpareparts:false,
                        searchTerm: '',
                        selectedFilterSparePartByType: Guid.createEmpty(),
                        selectedFilterSparePartByCommonProblem: Guid.createEmpty(),
                        selectedVariantId:''
                    };

   private unfilteredSparePartsForSelectedVariant: UnfilteredSparePartsForSelectedVariant={
       selectedVariantId : '',
       sparePartsForSelectedVariant:[]     
   }
    constructor() {
        super();
        this.translations = window.__litium.translation;
    }

    handleSearchResult(data: SearchSparePartResult, productType: string) {

        if(productType=="sparepart") {
            this.showSparePartTile = true;
        } else {
            this.showSparePartTile = false;
        }

        this.products = data.products;
        this.spareParts = data.spareParts;

        if (this.products.length == 0 && this.spareParts.length == 0) {
            this.noSearchResults = true;
        } else {
            this.noSearchResults = false;
        }
    }

    loadSparePartsForSelectedVariant(id: string, sparePartTypeFilter: Guid, selectedFilter:string, isCommonProblemFiltering:boolean) {
        this.spareParts =[];
        this.selectedFilterText = selectedFilter;
        if(!sparePartTypeFilter.isEmpty && id==''){
            id = SparePartShopStore.selectedVariantId;
        }
        
        SparePartShopStore.saveSelectedVariantId(id);
        this.sparePartFilter.selectedVariantId = id;
        var filterId=Guid.createEmpty();
        if(isCommonProblemFiltering){
            if(this.queryStringLoad){
                filterId= sparePartTypeFilter;
                this.sparePartFilter.selectedFilterSparePartByCommonProblem = sparePartTypeFilter;
            }
            else if(this.sparePartFilter.selectedFilterSparePartByCommonProblem==sparePartTypeFilter)
            {
                this.sparePartFilter.selectedFilterSparePartByCommonProblem=Guid.createEmpty();
                this.selectedFilterText = '';
            }
            else
            {
                filterId= sparePartTypeFilter;
                this.sparePartFilter.selectedFilterSparePartByCommonProblem = sparePartTypeFilter;
            }

            this.sparePartFilter.selectedFilterSparePartByType = Guid.createEmpty();
        }
        else{
            if(this.queryStringLoad){
                filterId= sparePartTypeFilter;
                this.sparePartFilter.selectedFilterSparePartByType = sparePartTypeFilter;
            }
            else if(this.sparePartFilter.selectedFilterSparePartByType==sparePartTypeFilter)
            {
                this.sparePartFilter.selectedFilterSparePartByType=Guid.createEmpty();
                this.selectedFilterText = '';
            }
            else
            {
                filterId= sparePartTypeFilter;
                this.sparePartFilter.selectedFilterSparePartByType = sparePartTypeFilter;
            }

            this.sparePartFilter.selectedFilterSparePartByCommonProblem = Guid.createEmpty();
        }

        this.generatePermaLink();

        if(this.unfilteredSparePartsForSelectedVariant.selectedVariantId!=id){
            ApiService.getProductSpareParts(id, Guid.createEmpty()).then((response) => {
                if (response && response.data) {
                    this.unfilteredSparePartsForSelectedVariant.selectedVariantId = id;
                    this.unfilteredSparePartsForSelectedVariant.sparePartsForSelectedVariant = response.data.spareParts;
                }
            });
        }

        ApiService.getProductSpareParts(id, filterId).then((response) => {
            if (response && response.data) {
                this.productSpareParts = response.data.spareParts;
                this.selectedProductName = response.data.selectedProductName; 
                this.selectedProductNameInternal= response.data.selectedProductName; 
            }
        });
    }

    clearSearchResult() {
        this.products = [];
        this.spareParts = [];
        this.productSpareParts = [];
        this.selectedProductName = '';
        this.selectedProductNameInternal= '';
        sessionStorage.setItem('spare-parts-search-term', '');
        sessionStorage.setItem('spare-parts-selected-variant', '');
        SparePartShopStore.saveSelectedVariantId('');

        this.generatePermaLink();
    }

    clearSparePartFilter(){
        this.sparePartFilter={ 
            searchSpareparts:false,
            searchTerm: '',
            selectedFilterSparePartByType: Guid.createEmpty(),
            selectedFilterSparePartByCommonProblem: Guid.createEmpty(),
            selectedVariantId:''
        };
        
        this.generatePermaLink();
    }
 
    loadSparePartsTypes(){
        ApiService.getSparePartsType().then((response) => {
            if (response && response.data) {
                this.sparePartsTypesFilter = response.data;

                if(!this.sparePartFilter.selectedFilterSparePartByType.isEmpty)
                {
                    if(this.sparePartsTypesFilter && this.sparePartsTypesFilter.length>0)
                    {
                        var x = this.sparePartsTypesFilter.find(y=>y.categorySystemId==this.sparePartFilter.selectedFilterSparePartByType);
                        if(x){
                            this.selectedFilterText = x.name;
                        }
                    }
                }
            }
        });
    }

    loadCommonProblems(){
        ApiService.getCommonProblems().then((response) => {
            if (response && response.data) {
                this.commonProblemsFilter = response.data;

                if(!this.sparePartFilter.selectedFilterSparePartByCommonProblem.isEmpty){
                    if(this.commonProblemsFilter && this.commonProblemsFilter.length>0)
                    {
                        var selectedCommonProblemsFilter = this.commonProblemsFilter.find(y=>y.categorySystemId==this.sparePartFilter.selectedFilterSparePartByCommonProblem);
                        if(selectedCommonProblemsFilter){
                            this.selectedFilterText = selectedCommonProblemsFilter.name;
                        }
                    }
                }
            }
        });
    }

    showFilterItemBasedOnCategoryId(categorySystemId){
        var showFilterItem = false;
        if(this.unfilteredSparePartsForSelectedVariant.sparePartsForSelectedVariant.length>0){
           var sparePart = this.unfilteredSparePartsForSelectedVariant.sparePartsForSelectedVariant.find(y=>y.categorySystemId==categorySystemId);
           showFilterItem = sparePart != undefined;
        }

        return showFilterItem;
    }

    showToolTip(id){
        this.hideAllToolTip();

        var toolTip = document.getElementById(id) ;
        if(toolTip){
            (<HTMLElement>toolTip).style.display = 'block';
        }
    }

    hideAllToolTip(){
        var toolTips = document.getElementsByClassName('tool-tip');
        if (toolTips && toolTips.length > 0){
            for(var i=0; i < toolTips.length; i++){
                (<HTMLElement>toolTips[i]).style.display = "none";
            }
        } 
    }

    generatePermaLink(){
        var currentlocation = location.pathname+'?ssp='+ this.sparePartFilter.searchSpareparts;

        if(this.sparePartFilter.searchTerm && this.sparePartFilter.searchTerm!=''){
            currentlocation +="&q="+this.sparePartFilter.searchTerm
        }

        if(this.sparePartFilter.selectedFilterSparePartByType && !this.sparePartFilter.selectedFilterSparePartByType.isEmpty){
            currentlocation +="&spt="+this.sparePartFilter.selectedFilterSparePartByType;
        }

        if(this.sparePartFilter.selectedFilterSparePartByCommonProblem && !this.sparePartFilter.selectedFilterSparePartByCommonProblem.isEmpty){
            currentlocation +="&spc="+this.sparePartFilter.selectedFilterSparePartByCommonProblem;
        }

        if(this.sparePartFilter.selectedVariantId && this.sparePartFilter.selectedVariantId!=''){
            currentlocation +="&sv="+this.sparePartFilter.selectedVariantId;
        }
        
        window.history.pushState(null, "", currentlocation);
    }

    setSparePartFilter(searchSpareparts:boolean, searchTerm:string, selectedFilterSparePartByType:Guid, 
        selectedFilterSparePartByCommonProblem:Guid, selectedVariantId:string){
        this.sparePartFilter.searchSpareparts = searchSpareparts;

        if(searchTerm){
            this.sparePartFilter.searchTerm = searchTerm;
        }

        if(selectedFilterSparePartByType){
            this.sparePartFilter.selectedFilterSparePartByType = selectedFilterSparePartByType;
        }

        if(selectedFilterSparePartByCommonProblem){
            this.sparePartFilter.selectedFilterSparePartByCommonProblem = selectedFilterSparePartByCommonProblem;
        }
        
        this.sparePartFilter.selectedVariantId = selectedVariantId;
        SparePartShopStore.saveSelectedVariantId(selectedVariantId);
        this.generatePermaLink();
    }

    public created(){
        window.onpopstate = function () {
            location.reload();
        };
        
        this.loadSparePartsTypes();
        this.loadCommonProblems();
        this.loadFromQueryString();
        this.queryStringLoad = false;
    }

    public loadFromQueryString(){
        this.sparePartFilter={ 
            searchSpareparts:false,
            searchTerm: '',
            selectedFilterSparePartByType: Guid.createEmpty(),
            selectedFilterSparePartByCommonProblem: Guid.createEmpty(),
            selectedVariantId:''
        };

        var query = queryString.parse(location.search);
        if (query.ssp) {
            if(query.ssp.toLowerCase()=="true")
                this.sparePartFilter.searchSpareparts = true;
            else
                this.sparePartFilter.searchSpareparts = false;
        }

        if (query.q) {
            this.sparePartFilter.searchTerm = query.q;
        }
           
        if (query.spt) {
            this.sparePartFilter.selectedFilterSparePartByType = query.spt;
        }

        if (query.spc) {
            this.sparePartFilter.selectedFilterSparePartByCommonProblem = query.spc;
        }

        if (query.sv) {
            this.sparePartFilter.selectedVariantId = query.sv;
        }
        
        if(!this.sparePartFilter.selectedFilterSparePartByType.isEmpty)
        {
            if(this.sparePartsTypesFilter && this.sparePartsTypesFilter.length>0)
            {
                var x = this.sparePartsTypesFilter.find(y=>y.categorySystemId==this.sparePartFilter.selectedFilterSparePartByType);
                if(x){
                    this.selectedFilterText = x.name;
                }
            }

            this.loadSparePartsForSelectedVariant(this.sparePartFilter.selectedVariantId, this.sparePartFilter.selectedFilterSparePartByType, this.selectedFilterText, false);
        }
        else if(!this.sparePartFilter.selectedFilterSparePartByCommonProblem.isEmpty){
            if(this.commonProblemsFilter && this.commonProblemsFilter.length>0)
            {
                var selectedCommonProblemsFilter = this.commonProblemsFilter.find(y=>y.categorySystemId==this.sparePartFilter.selectedFilterSparePartByType);
                if(selectedCommonProblemsFilter){
                    this.selectedFilterText = selectedCommonProblemsFilter.name;
                }
            }
            
            this.loadSparePartsForSelectedVariant(this.sparePartFilter.selectedVariantId, this.sparePartFilter.selectedFilterSparePartByCommonProblem, this.selectedFilterText, true);
        }
        else{

            if(this.sparePartFilter.selectedVariantId!='')
            {
                this.loadSparePartsForSelectedVariant(this.sparePartFilter.selectedVariantId, Guid.createEmpty(), this.selectedFilterText, false);
            }
        }
    }
} 
