import { ConfiguratorPart, ConfiguratorPartType, ConfiguratorVariant, ConfiguratorMode } from "@/models/tapwell/Configurator";
import { ConfiguratorClient } from "@/services/tapwell/ConfiguratorClient";
import { Component, Prop, Vue } from "vue-property-decorator";
import ScrollShadow from "@/components/shared/ScrollShadow.vue";
import { PropType } from "vue";
import { dectectMobileDevice } from "@/scripts/shared/sharedScripts.js";
import { Guid } from "guid-typescript";


@Component({
    data: () => {
        return {
            selectedVariantInternal: undefined,
            allVariantsInternal: [],
            ConfiguratorPartType
        }
    },
    watch:{
        reopenShowerConfigurator: function(newVal, oldVal){
            if(newVal != oldVal){
                this.fetchShowerConfiguratorModel();
            }
        }
    },
    components:{
        ScrollShadow
    },
    
})
export default class ShowerConfigurator extends Vue {
    public translations: any;

    @Prop()
    public variantid!: string;

    @Prop()
    public reopenShowerConfigurator!: boolean;

    @Prop(
        {
            type: String as PropType<ConfiguratorMode>,    
            validator: ((x) => { return Object.values(ConfiguratorMode).includes(x)})        
        }        
    )
    public mode!: ConfiguratorMode;

    @Prop()
    public showerconfiguratorpdfurl!: string;

    @Prop()
    public configuratorOptionTitle1!: string;

    @Prop()
    public configuratorOptionTitle2!: string;

    @Prop()
    public configuratorOptionTitle3!: string;
    
    @Prop()
    public configuratorOptionTitle4!: string;

    @Prop()
    public pagesystemid!: Guid;

    public configuratorOptionTitle1Internal!: string;

    get configuratoroptiontitle1internal(): string {
        return this.configuratorOptionTitle1Internal;
    }

    public configuratorOptionTitle2Internal!: string;

    get configuratoroptiontitle2internal(): string {
        return this.configuratorOptionTitle2Internal;
    }

    public configuratorOptionTitle3Internal!: string;

    get configuratoroptiontitle3internal(): string {
        return this.configuratorOptionTitle3Internal;
    }

    public configuratorOptionTitle4Internal!: string;

    get configuratoroptiontitle4internal(): string {
        return this.configuratorOptionTitle4Internal;
    }

    public pageSystemIdInternal!: Guid;

    get pagesystemidinternal(): Guid {
        return this.pageSystemIdInternal;
    }

    private loadingdata: boolean = true;

    private surfaceOpen: boolean = false;

    private showerHeadOpen: boolean = false;

    private showerArmOpen: boolean = false;

    private requestContext: any;

    constructor() {
        super();
        this.translations = window.__litium.translation;
        this.requestContext = window.__litium.requestContext;
        this.configuratorOptionTitle1Internal = this.configuratorOptionTitle1;
        this.configuratorOptionTitle2Internal = this.configuratorOptionTitle2;
        this.configuratorOptionTitle3Internal = this.configuratorOptionTitle3;
        this.configuratorOptionTitle4Internal = this.configuratorOptionTitle4;
        this.pageSystemIdInternal = this.pagesystemid;
    }

    get selectedVariant(): ConfiguratorVariant {
        return this.$data.selectedVariantInternal;
    }

    set selectedVariant(value: ConfiguratorVariant) {
        this.$data.selectedVariantInternal = value;
    }

    get allVariants(): ConfiguratorVariant[] {
        return this.$data.allVariantsInternal;
    }

    set allVariants(value: ConfiguratorVariant[]) {
        this.$data.allVariantsInternal = value;
    }

    get selectedSurface(): string | undefined {
        let surface = this.selectedVariant.configuratorParts.find(y => y.partType == ConfiguratorPartType.Surface);
        if (surface) {
            return surface.value;
        }

        return undefined;
    }

    get selectedShowerArm(): ConfiguratorPart | undefined {
        return this.selectedVariant.configuratorParts.find(y => 
            y.partType == ConfiguratorPartType.ShowerArmWall || 
            y.partType == ConfiguratorPartType.ShowerArmCeiling);
    }

    get selectedShowerHead(): ConfiguratorPart | undefined {
        return this.selectedVariant.configuratorParts.find(y => 
            y.partType == ConfiguratorPartType.ShowerHeadRound || 
            y.partType == ConfiguratorPartType.ShowerHeadRectangular || 
            y.partType == ConfiguratorPartType.ShowerHeadSquare ||
            y.partType == ConfiguratorPartType.ShowerHeadFlush);
    }

    get selectedExtraPart(): ConfiguratorPart | undefined {
        return this.selectedVariant.configuratorParts.find(y => 
            y.partType == ConfiguratorPartType.ExtraPart);
    }

    get selectedVariantPrice(): string {
        return `${this.selectedVariant.displayPrice}`;
    }

    getShowerArmPrice(currentShowerArm: ConfiguratorPart) : string {
        var result = "";
        if (this.selectedShowerArm) {
            const price = currentShowerArm.price - this.selectedShowerArm.price;            
            if(price > 0){
                result = `+${Math.round(price)} ${currentShowerArm.currencySymbol}`;
            }else if(price == 0){
                return "";
            }else{
                result = `${Math.round(price)} ${currentShowerArm.currencySymbol}`;
            }
        }
        return result;
    }

    getShowerHeadPrice(currentShowerHead: ConfiguratorPart) : string {
        var result = "";
        if (this.selectedShowerHead) {
            const price = currentShowerHead.price - this.selectedShowerHead.price;            
            if(price > 0){
                result = `+${Math.round(price)} ${currentShowerHead.currencySymbol}`;
            }else if(price == 0){
                return "";
            }else{
                result = `${Math.round(price)} ${currentShowerHead.currencySymbol}`;
            }
        }
        return result;
    }

    getExtraPartPrice(currentExtraPart:ConfiguratorPart): string {
        var result = "";
        if (this.selectedExtraPart) {
            const price = currentExtraPart.price - this.selectedExtraPart.price;            
            if(price > 0){
                result = `+${Math.round(price)} ${currentExtraPart.currencySymbol}`;
            }else if(price == 0){
                return "";
            }else{
                result = `${Math.round(price)} ${currentExtraPart.currencySymbol}`;
            }
        }
        return result;
    }

    get allSurfaces(): ConfiguratorPart[] {
        let surfaces: ConfiguratorPart[] = [];
        this.allVariants.forEach(variant => {
            let surface = variant.configuratorParts.find(y => y.partType == ConfiguratorPartType.Surface)
            if (surface && this.selectedVariant) {
                const surfaceValue = surface.value;
                if (variant.id == this.selectedVariant.id) {
                    surface.selected = true;
                    let existingSurface = surfaces.find(x => x.value === surfaceValue)
                    if (existingSurface){
                        surfaces = surfaces.filter(x => x !== existingSurface);
                    }
                    surfaces.push(surface)
                } else {
                    let existingSurface = surfaces.find(x => x.value === surfaceValue)
                    surface.selected = false;
                    if (!existingSurface){
                        surfaces.push(surface)
                    }
                }
            }            
        });

        surfaces.sort((a: ConfiguratorPart, b: ConfiguratorPart) => {
            const textA = a.value.toUpperCase();
            const textB = b.value.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        
        return surfaces;
    }

    get allShowerArms(): ConfiguratorPart[] {
        let showerArms: ConfiguratorPart[] = [];

        if (this.selectedShowerArm === undefined) {
            return showerArms;
        }

        this.allVariants.forEach(variant => {
            let showerArm = variant.configuratorParts.find(y => y.
                partType == ConfiguratorPartType.ShowerArmWall || 
                y.partType == ConfiguratorPartType.ShowerArmCeiling)
            if (showerArm && this.selectedVariant) {
                if (variant.id == this.selectedVariant.id) {
                    const showerArmId = showerArm.id;
                    let showerArmAlreadyAdded = showerArms.find(x => x.id == showerArmId)
                    if (showerArmAlreadyAdded) {
                        showerArmAlreadyAdded.selected = true;
                    } else {
                        showerArm.selected = true;
                        showerArms.push(showerArm)
                    }
                } else {
                    if (this.sameSurface(variant)) {
                        const showerArmId = showerArm.id;
                        const showerArmAlreadyAdded = showerArms.find(x => x.id == showerArmId)
                        if (!showerArmAlreadyAdded) {
                            showerArm.selected = false;
                            showerArms.push(showerArm)
                        }
                    }
                }
            } 
        });

        showerArms.sort((a: ConfiguratorPart, b: ConfiguratorPart) => {
            if (a.partType === b.partType) {
                return a.value < b.value ? -1 : 1;
            } else {
                return a.partType > b.partType ? -1 : 1;
            }
        });

        return showerArms;
    }

    get allShowerHeads(): ConfiguratorPart[] {
        let showerHeads: ConfiguratorPart[] = [];
        this.allVariants.forEach(variant => {
            let showerHead = variant.configuratorParts.find(y => 
                y.partType == ConfiguratorPartType.ShowerHeadRound || 
                y.partType == ConfiguratorPartType.ShowerHeadRectangular || 
                y.partType == ConfiguratorPartType.ShowerHeadSquare || 
                y.partType == ConfiguratorPartType.ShowerHeadFlush)
            if (showerHead && this.selectedVariant) {
                if (variant.id == this.selectedVariant.id) {
                    const showerHeadId = showerHead.id;
                    let showerHeadAlreadyAdded = showerHeads.find(x => x.id == showerHeadId);
                    if (showerHeadAlreadyAdded) {
                        showerHeadAlreadyAdded.selected = true;
                    } else {
                        showerHead.selected = true;
                        showerHeads.push(showerHead)
                    }
                } else {
                    if (this.sameSurface(variant)) {
                        const showerHeadId = showerHead.id;
                        const showerHeadAlreadyAdded = showerHeads.find(x => x.id == showerHeadId)
                        if (!showerHeadAlreadyAdded) {
                            showerHead.selected = false;
                            showerHeads.push(showerHead)
                        }
                    }
                }
            } 
        });

        showerHeads.sort((a: ConfiguratorPart, b: ConfiguratorPart) => {
            if (a.partType === b.partType) {
                return a.value < b.value ? -1 : 1;
            } else {
                return a.partType > b.partType ? -1 : 1;
            }
        });

        return showerHeads;
    }

    get allExtraParts(): ConfiguratorPart[] {
        let extraParts: ConfiguratorPart[] = [];
        this.allVariants.forEach(variant => {
            let extraPart = variant.configuratorParts.find(y => 
                y.partType == ConfiguratorPartType.ExtraPart)
            if (extraPart && this.selectedVariant) {
                if (variant.id == this.selectedVariant.id) {
                    const extraPartId = extraPart.id;
                    let extraPartAlreadyAdded = extraParts.find(x => x.id == extraPartId);
                    if (extraPartAlreadyAdded) {
                        extraPartAlreadyAdded.selected = true;
                    } else {
                        extraPart.selected = true;
                        extraParts.push(extraPart)
                    }
                } else {
                    if (this.sameSurface(variant)) {
                        const extraPartId = extraPart.id;
                        const extraPartAlreadyAdded = extraParts.find(x => x.id == extraPartId)
                        if (!extraPartAlreadyAdded) {
                            extraPart.selected = false;
                            extraParts.push(extraPart)
                        }
                    }
                }
            } 
        });

        extraParts.sort((a: ConfiguratorPart, b: ConfiguratorPart) => {
            if (a.partType === b.partType) {
                return a.value < b.value ? -1 : 1;
            } else {
                return a.partType > b.partType ? -1 : 1;
            }
        });

        return extraParts;
    }

    selectSurface(newSurface: string): void {
        let newSelectedVariant: ConfiguratorVariant | undefined;
        for (let variant of this.allVariants) {
            const surface = variant.configuratorParts.find(y => y.partType === ConfiguratorPartType.Surface && y.value === newSurface);
            if (surface) {
                newSelectedVariant = variant;
                if (this.sameShowerHead(variant) && this.sameShowerArm(variant)) {
                    newSelectedVariant = variant;
                    break;
                } 
                if (this.sameShowerHead(variant)) {
                    newSelectedVariant = variant;
                    if (!this.selectedShowerArm){
                        break;
                    }
                }
                if (this.sameShowerArm(variant)) {
                    newSelectedVariant = variant;
                }
            }
        }

        if (newSelectedVariant) {
            this.selectedVariant = newSelectedVariant;
        } else {
            this.selectedVariant = this.allVariants[0];
        }
    }

    selectShowerHead(itemId: string): void {
        let newSelectedVariant: ConfiguratorVariant | undefined;
        for (let variant of this.allVariants) {
            const showerHead = variant.configuratorParts.find(y => y.id === itemId);
            if (showerHead) {
                if (this.sameSurface(variant) && this.sameShowerArm(variant)) {
                    newSelectedVariant = variant;
                    break;
                }
                if (this.sameSurface(variant)) {
                    newSelectedVariant = variant;
                }
            }
        }

        if (newSelectedVariant) {
            this.selectedVariant = newSelectedVariant;
        } else {
            this.selectedVariant = this.allVariants[0];
        }
    }

    selectShowerArm(itemId: string): void {
        let newSelectedVariant: ConfiguratorVariant | undefined;
        for (let variant of this.allVariants) {
            const showerArm = variant.configuratorParts.find(y => y.id === itemId);
            if (showerArm) {
                if (this.sameSurface(variant) && this.sameShowerHead(variant)) {
                    newSelectedVariant = variant;
                    break;
                }
                if (this.sameSurface(variant)) {
                    newSelectedVariant = variant;
                }
            }
        }

        if (newSelectedVariant) {
            this.selectedVariant = newSelectedVariant;
        } else {
            this.selectedVariant = this.allVariants[0];
        }
    }

    selectExtraPart(itemId: string): void {
        let newSelectedVariant: ConfiguratorVariant | undefined;
        for (let variant of this.allVariants) {
            const extraPart = variant.configuratorParts.find(y => y.id === itemId);
            if (extraPart) {
                if (this.sameSurface(variant) && this.sameExtraPart(variant)) {
                    newSelectedVariant = variant;
                    break;
                }
                if (this.sameSurface(variant)) {
                    newSelectedVariant = variant;
                }
            }
        }

        if (newSelectedVariant) {
            this.selectedVariant = newSelectedVariant;
        } else {
            this.selectedVariant = this.allVariants[0];
        }
    }

    sameSurface(variant: ConfiguratorVariant): boolean {
        const surface = variant.configuratorParts.find(y => y.partType == ConfiguratorPartType.Surface)
        if (surface && surface.value === this.selectedSurface) {
            return true;
        }
        return false;
    }

    sameShowerHead(variant: ConfiguratorVariant): boolean {
        const showerHead = variant.configuratorParts.find(y => 
            y.partType == ConfiguratorPartType.ShowerHeadRound || 
            y.partType == ConfiguratorPartType.ShowerHeadRectangular || 
            y.partType == ConfiguratorPartType.ShowerHeadSquare ||
            y.partType == ConfiguratorPartType.ShowerHeadFlush)
        if (showerHead && this.selectedShowerHead && showerHead.id === this.selectedShowerHead.id) {
            return true;
        }
        return false;
    }

    sameShowerArm(variant: ConfiguratorVariant): boolean {
        const showerArm = variant.configuratorParts.find(y => 
            y.partType == ConfiguratorPartType.ShowerArmWall || 
            y.partType == ConfiguratorPartType.ShowerArmCeiling)
        if (showerArm && this.selectedShowerArm && showerArm.id === this.selectedShowerArm.id) {
            return true;
        }
        return false;
    }

    sameExtraPart(variant: ConfiguratorVariant): boolean {
        const extraPart = variant.configuratorParts.find(y => 
            y.partType == ConfiguratorPartType.ExtraPart)
        if (extraPart && this.selectedExtraPart && extraPart.id === this.selectedExtraPart.id) {
            return true;
        }
        return false;
    }

    saveConfig(){ 
        this.$emit('save-configurator', this.selectedVariant.id);
    }

    closeShowerConfigurator(){
        this.$emit('close-configurator');
    }

    IsMobileDevice(maxWidth: string){
        return dectectMobileDevice(maxWidth);
    }

    goToProduct(){
        window.open(this.selectedVariant.url,"_blank");
    }

    openShowerConfiguratorPdf(){
        var token = window.localStorage.getItem("requestVerificationToken");
        var verificationToken='';
        if(token!=null){
            verificationToken = token.split(':')[0];
        }

        if(verificationToken!=''){
            var pdfUrl = this.showerconfiguratorpdfurl + this.selectedVariant.id +"&pageSystemId="+this.pagesystemid+"&token=" + verificationToken;
            window.open(pdfUrl,"_blank");
        }
    }

    async fetchShowerConfiguratorModel(){
        ConfiguratorClient.getShowerConfiguratorModel(this.variantid, this.pagesystemid).then((response) => {
            this.allVariants = response.data.configuratorVariants;
            const variant = this.allVariants.find(y => y.selected);
            if (variant) {
                this.selectedVariant = variant;
            }
            
            this.loadingdata = false;
            
        });
    }

    async mounted(){
        this.fetchShowerConfiguratorModel();
    }
}
