import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CartViewB2c from "./CartViewB2C.vue";
import { CheckoutModel } from "@/models/tapwell/Checkout";
import { CheckoutStore } from "@/store/tapwell/checkoutstate";
import { ApiService } from "@/services/tapwell/ApiService";

@Component({
    components : {
        CartViewB2c,
    },
})

export default class CheckoutB2C extends Vue {
    @Prop()
    protected viewmodel!: CheckoutModel;

    private viewmodelInternal!: CheckoutModel;

    get cart() { return CheckoutStore.cart; }

    get checkoutmodel(): CheckoutModel {
        return this.viewmodelInternal;
    }

    set checkoutmodel(value: CheckoutModel) {
        this.viewmodelInternal = value;
    }
    
    constructor() {
        super();
        this.checkoutmodel = this.viewmodel;
    }

    @Watch("cart.cartTotalExclDeliveryCost")
    cartTotalUpdated(newValue: string, oldValue: string) {
        if (newValue !== oldValue) {
            this.setPaymentProvider();
        }
    }

    setPaymentProvider() {
        ApiService.setPaymentProvider(this.checkoutmodel).then((response) => {
            if (response && response.data) {
                CheckoutStore.reloadState().then(() => { 
                    this.checkoutmodel = response.data;
                    var checkoutContainer = document.getElementById('checkout-widget');
                    if (checkoutContainer) {
                        checkoutContainer.innerHTML = this.checkoutmodel.paymentWidget.responseString;
                        var scriptsTags = checkoutContainer.getElementsByTagName('script')
                        for (var i = 0; i < scriptsTags.length; i++) {
                            var parentNode = scriptsTags[i].parentNode
                            var newScriptTag = document.createElement('script')
                            newScriptTag.type = 'text/javascript'
                            newScriptTag.text = scriptsTags[i].text
                            if (parentNode) {
                                parentNode.removeChild(scriptsTags[i])
                                parentNode.appendChild(newScriptTag)
                            }
                        }
                    }

                    setTimeout(()=>this.setShippingOption(), 1000);
                 });
            }
        });
    }

    setShippingOption() {
        window._klarnaCheckout && window._klarnaCheckout((api) => {
            api.on({
                'shipping_option_change': (data) => {                                
                    //console.log(`On shipping option change: ${data}`);
                    this.checkoutmodel.klarnaSelectedShippingOption = data;
                    ApiService.setShippingOption(this.checkoutmodel).then((response) => {
                        CheckoutStore.reloadState().then(() => { });
                    });
                }
            });
        });
    }
}