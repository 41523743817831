import { Component, Prop, Vue } from "vue-property-decorator";
import { TapwellGroupWebsite } from "@/models/TapwellGroupWebsite";
import { Product } from "@/models/tapwell/Product";
import ProductTile from "../Product/ProductTile.vue";
import ProductTileHaven from "../Product/ProductTileHaven.vue";

@Component({
    components: {
        ProductTile,
        ProductTileHaven,
    },
})
export default class ProductList extends Vue {
    @Prop()
    protected products!: Product[];

    constructor() {
        super();
    }

    get isHavenB2B() {
        return window.__currentWebsite === TapwellGroupWebsite.HavenB2B;
    }
}
