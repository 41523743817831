import { Component, Prop, Vue } from "vue-property-decorator";
import { OrganizationProject, SelectListOptions } from "@/models/OrganizationProject";
import Autocomplete from "vuejs-auto-complete";
@Component({
    components: {
        Autocomplete,
    },
})
export default class SelectOrganization extends Vue {
    @Prop()
    protected viewmodel!: OrganizationProject[];
    protected setorganization: any;
    protected translations: any;

    private viewmodelInternal!: OrganizationProject[];

    get organizationProjectList(): OrganizationProject[] {
        return this.viewmodelInternal;
    }
    set organizationProjectList(value: OrganizationProject[]) {
        this.viewmodelInternal = value;
    }

    public optionlist: SelectListOptions[] = [];

    constructor() {
        super();

        this.translations = window.__litium.translation;

        this.organizationProjectList = this.viewmodel;
        this.organizationProjectList.forEach(row => {
            this.optionlist.push({
                id: row.organization.value,
                name: row.organization.text,
                type: "organization",
                organizationId: row.organization.value,
            });
            if (row.projects.length > 0) {
                row.projects.forEach(element => {
                    this.optionlist.push({
                        id: element.value,
                        name: element.text,
                        type: "project",
                        organizationId: row.organization.value,
                    });
                });
            }
        });
    }

    //Set value to parent razor page on autocomplete @change.
    public changeOrganizationProject(item) {
        var selectedOrganization = <HTMLInputElement>document.querySelector("#SelectedOrganization");
        var selectedProjectOrganization = <HTMLInputElement>document.querySelector("#SelectedProjectOrganization");

        if (item.selectedObject.type == "organization") {
            if (selectedProjectOrganization != undefined && selectedProjectOrganization != null) {
                selectedProjectOrganization.value = "";
            }
            selectedOrganization.value = item.value;
        } else {
            if (selectedOrganization != undefined && selectedOrganization != null) {
                selectedOrganization.value = "";
            }

            selectedProjectOrganization.value = item.value;
            selectedOrganization.value = item.selectedObject.organizationId;
        }
    }

    public clearSelection() {
        var selectedOrganization = <HTMLInputElement>document.querySelector("#SelectedOrganization");
        var selectedProjectOrganization = <HTMLInputElement>document.querySelector("#SelectedProjectOrganization");
        if (selectedProjectOrganization != undefined && selectedProjectOrganization != null) {
            selectedProjectOrganization.value = "";
        }

        if (selectedOrganization != undefined && selectedOrganization != null) {
            selectedOrganization.value = "";
        }
    }
}
