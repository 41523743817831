import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    name: "ProductInspirationalImages",
    props:["urls", "varianImage"],
  components: {
    VueEasyLightbox
  },
  data() {
    return {
      imgs: '',  // Img Url , string or Array of string
      visible: false,
      index: 0 ,  // default: 0
      firstThree:'',
      showMore:false,
      firstThreeWithVariant:[]
    }
  },
  computed:{
    translations(){
      return window.__litium.translation;
  }
  },
  watch: {
    // This would be called anytime the value of urls changes
    urls(newValue, oldValue) {
      this.firstThreeUrl()
    }
  },
  methods: {
    showSingle(showIndex) {
      this.index = showIndex;
      this.imgs = this.firstThreeWithVariant;
      this.show()
    },
    showMultiple() {
      this.index = 3;
      this.imgs = this.firstThreeWithVariant;
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    firstThreeUrl() {
      this.firstThreeWithVariant=[]
      this.firstThreeWithVariant.push(this.varianImage);
      this.firstThreeWithVariant.push(...this.urls);
      this.firstThree = this.firstThreeWithVariant.slice(0, 3)       
      if(this.firstThreeWithVariant.length>3)
        {          
          this.showMore=true;
         }  
         else{
          this.showMore=false;
         }
    },
  },
  beforeMount(){
    this.firstThreeUrl()
 }  
}