import { Component, Prop, Vue } from "vue-property-decorator";
import { CheckoutStore, CheckoutState } from "../../../store/tapwell/checkoutstate";
import { ApiService } from "../../../services/tapwell/ApiService";
import { Spinner } from "spin.js";
import { ReorderCart } from "@/models/tapwell/Cart";
import { Guid } from "guid-typescript";

@Component
export default class ReorderButton extends Vue {

    @Prop()
    private linktext!: string;

    @Prop()
    private orderid!: Guid;

    @Prop()
    private cssclass!: string;

    protected spinner: Spinner;

    constructor() {
        super();
        this.spinner = new Spinner({ scale: 0.5 });
    }

    protected addToCart() {
        this.spinner.spin(this.$el as HTMLElement);
        const orderPayload: ReorderCart = {
            orderId: this.orderid,
        };
        var header = <HTMLElement>document.querySelector(".header");
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        const currentScroll = window.pageYOffset;
 
        if (currentScroll == 0) {
            header.classList.remove(scrollUp);
            header.classList.remove(scrollDown);
     
        } else {
            header.classList.add(scrollUp);
            header.classList.remove(scrollDown);

        }
        ApiService.reorderCart(orderPayload).then((response) => {
            if (response && response.data) {
                const payload: CheckoutState = {
                    cart: response.data,
                };
                CheckoutStore.saveState(payload).then(() => {/*nothing*/});
            }
            this.spinner.stop();
        });
    }
}
