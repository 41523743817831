import { render, staticRenderFns } from "./ImageBank.vue?vue&type=template&id=158f5e34&scoped=true&"
import script from "./imagebank.ts?vue&type=script&lang=ts&"
export * from "./imagebank.ts?vue&type=script&lang=ts&"
import style0 from "./ImageBank.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ImageBank.vue?vue&type=style&index=1&id=158f5e34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158f5e34",
  null
  
)

export default component.exports