import { Component, Prop, Vue } from "vue-property-decorator";
import { ApiService } from "@/imagebank/api";
import { imageBankStore } from "@/imagebank/imagebank-state";
import { ImageBankFilterModel, ImageBankImageModel } from "@/imagebank/model";
import { Button, Checkbox } from "@/imagebank/components";
import ImageModal from "@/imagebank/components/image-modal/ImageModal.vue";
import ImageTile from "@/imagebank/components/image-tile/ImageTile.vue";
import ImageFilter from "@/imagebank/components/image-filter/ImageFilter.vue";
import ImageList from "@/imagebank/components/image-list/ImageList.vue";
import SelectedImagesModal from "@/imagebank/components/seleted-images-modal/SelectedImagesModal.vue";

@Component({
    components: {
        "v-button": Button,
        "v-checkbox": Checkbox,
        ImageModal,
        ImageTile,
        ImageFilter,
        ImageList,
        SelectedImagesModal,
    },
})
export default class ImageBank extends Vue {
    @Prop()
    bricmateLogo!: string;
    @Prop()
    havenLogo!: string;
    @Prop()
    tapwellLogo!: string;
    @Prop()
    descriptionText!: string;
    translations: any;

    modalImage!: ImageBankImageModel | null;
    images = new Array<ImageBankImageModel>();
    imageFilters!: ImageBankFilterModel;
    showSelectedImages = false;
    allSelectedImages = false;

    noResult: boolean = false;

    private pageIndex: number = 2;
    public showLoadMoreButton: boolean = true;
    loading: boolean = false;

    //@Prop()
    private searchTermInternal!: {
        search: string;
        color?: string[] | undefined;
        brand?: string[] | undefined;
        categories?: string[] | undefined;
        imageTypes?: string[] | undefined;
        pageIndex: number;
    };
    constructor() {
        super();
        this.translations = window.__litium.translation;

        this.modalImage = null;
        this.imageFilters = {} as ImageBankFilterModel;

    }

    mounted() {
        ApiService.getFilters().then(x => (this.imageFilters = x.data));
    }


    get searchTerm() {
        return this.searchTermInternal;
    }
    set searchTerm(v) {
        this.searchTermInternal = v;
    }
    get selectedImages() {
        return imageBankStore.selectedImages;
    }

    onShowModal(image: ImageBankImageModel) {
        this.modalImage = image;
    }

    onSearch(filter: { search: string; color?: string[]; brand?: string[]; categories?: string[]; imageTypes?: string[]; pageIndex: number }) {
        this.searchTerm = filter;
        this.pageIndex = 1;
        filter.pageIndex = this.pageIndex;
        this.loading = true;
        ApiService.search(filter).then(result => {
            this.images = [...result.data];
            this.searchTerm.pageIndex += 1;
            this.loading = false;
            if (result.data.length == 0) {
                this.noResult = true;
            } else {
                this.noResult = false;
            }
        });
    }

    selectAllImages(images: ImageBankImageModel[]) {
        images.forEach(image => {
            imageBankStore.addSelectedImage(image);
            this.allSelectedImages = true;
        });
    }

    removeAllImages(images: ImageBankImageModel[]) {
        images.forEach(image => {
            imageBankStore.removeSelectedImage(image);
            this.allSelectedImages = false;
        });
    }

    onToggleImage(image: ImageBankImageModel) {
        if (this.selectedImages.find(x => x.articleNumber === image.articleNumber)) {
            imageBankStore.removeSelectedImage(image);
        } else {
            imageBankStore.addSelectedImage(image);
        }
    }

    onDownload(data: { resolution: "high" | "low"; ids: string[] }) {
        ApiService.downloadImages(data.resolution, data.ids);
    }

    public loadMoreProducts() {
        if (this.searchTerm) {
            this.loading = true;
            ApiService.search(this.searchTerm).then(result => {
                this.loading = false;
                if (result && result.data) {
                    this.images = this.images.concat(result.data);
                    this.searchTerm.pageIndex += 1;
                }
            });
        }
    }
}
