import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Checkbox } from "@/imagebank/components";
import { ImageBankImageModel } from "@/imagebank/model";
import ImageModal from "@/imagebank/components/image-modal/ImageModal.vue";
import { imageBankStore } from "@/imagebank/imagebank-state";

@Component({
    components: {
        "v-checkbox": Checkbox,
        ImageModal,
    },
})
export default class ImageTile extends Vue {
    @Prop({ default: undefined })
    image!: ImageBankImageModel;

    translations: any;

    constructor() {
        super();
        this.translations = window.__litium.translation;
    }

    get selected() {
        return !!imageBankStore.selectedImages.find(x => x.articleNumber === this.image.articleNumber);
    }

    @Emit("onShowModal")
    onShowModal() {
        return this.image;
    }

    @Emit("download")
    onDownload(resolution: "high" | "low") {
        return {
            resolution,
            ids: [this.image.id],
        };
    }

    @Emit("toggle-selection")
    toggleSelection() {
        return this.image;
    }

    convertByteToMB(data: string) {
        const const_term = 1024;
        return (parseInt(data) / const_term ** 2).toFixed(1) + "mb";
    }
}
