import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ProductTileB2c from "../Product/ProductTileB2C.vue";
import { ProductStore } from "@/store/tapwell/productstate";
import { Product, SearchProductsResult, Variant } from "@/models/tapwell/Product";
import { ApiService } from "@/services/tapwell/ApiService";
import { CategoryNavigationItem } from "@/models/tapwell/Category";

@Component({
  components: {
    ProductTileB2c,
  },
})
export default class SearchResultB2c extends Vue {

  @Prop()
  protected searchWord!: string;

  protected infiniteId: any = "default";
  protected isLoading: boolean = false;
  protected noResult: boolean = false;
  private modalopen : boolean = true;

  private executedSearchWord: string = "";

  public translations: any;

  private categorySearchResult!: CategoryNavigationItem[];
  private searchWordInternal: string = "";

  constructor() {
    super();
    this.translations = window.__litium.translation;
    this.categorySearchResult = [];
  }

  public mounted() {
    setTimeout(() => {
      if (this.executedSearchWord == this.searchWord) {
        return;
      }

      this.executedSearchWord = this.searchWord;
      this.isLoading = true;
      this.noResult = false;
      ApiService.searchB2cProducts(this.searchWord).then((response) => {
        if (response && response.data) {
          this.handlePageSearch(response.data);
        }
      }).finally(() => {
        this.isLoading = false;
  
        if (ProductStore.searchresult.length == 0) {
          this.noResult = true;
        }
      });
    }, 2000);
  }

 
  get searchresult(): Product[] {
    return ProductStore.searchresult;
  }

  set searchterminternal(value: string) {
    this.searchWordInternal = value;
}

get searchterminternal(): string{
    return this.searchWordInternal;
}

  @Watch("searchterminternal")
  public termChanged(newTerms: string, oldValue: string) {
    setTimeout(() => {
      if (this.executedSearchWord == this.searchterminternal) {
        return;
      }

      this.executedSearchWord = this.searchterminternal;
      this.isLoading = true;
      this.noResult = false;
      ApiService.searchB2cProducts(this.searchterminternal).then((response) => {
        if (response && response.data) {
          this.handlePageSearch(response.data);
        }
      }).finally(() => {
        this.isLoading = false;
  
        if (ProductStore.searchresult.length == 0) {
          this.noResult = true;
        }
      });
    }, 2000);
  }

  search(event: Event) {
    var input = this.$refs.searchb2c as HTMLInputElement;
    var term = input.value;

    ProductStore.saveSearchResult([] as Product[]);

    if (term.length <= 1) {
      return;
    }
    var currentlocation = location.pathname+"?q="+term;
    window.history.pushState(null, "", currentlocation);
    this.searchterminternal = term;
  }

  handlePageSearch(data: SearchProductsResult) {
    if (this.searchWord == data.term) {
      ProductStore.saveSearchResult(ProductStore.searchresult.concat(data.products));
      this.categorySearchResult = data.categories;
    }
  }

  selectVariant(product: Product, variant: Variant): void {
    document.location.href = variant.url;
  }

  toggleModal() {
    this.$emit('toggleFromModal');
  }

  productTileShouldBeHighlighted(index: number) {
    return index == 0 || (index + 1) % 14 == 0;
  }
}
