import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import "../../models/Window";
import { Guid } from "guid-typescript";
import { Cart, AddOrUpdateCartItem, ReorderCart, ProductPriceStockRequest, ProductPriceStock, ProductAvailableResponse, JeevesPriceRequest, JeevesPriceResponse } from "@/models/tapwell/Cart";
import { CategoryModel } from "@/models/tapwell/Category";
import { CheckoutModel, CampaignModel } from "@/models/tapwell/Checkout";
import { Product, SearchProductsResult, SearchSparePartResult, ProductSparePartsResult, SparePartsTypeResult, CommonProblemType } from "@/models/tapwell/Product";
import { CheckoutQuery } from "@/models/tapwell/Unifaun";
import { CustomerResponseModel } from "@/models/tapwell/CustomerService";
function errorResponse(e: any): Promise<AxiosResponse<any>> {
    const response: AxiosResponse<boolean> = {
        data: false,
        status: e.response.status,
        statusText: e.response.data,
        headers: {},
        request: {},
        config: (null as unknown) as AxiosRequestConfig,
    };
    return Promise.resolve(response);
}

export const ApiService = {
    async checkoutOrder(model: CheckoutModel): Promise<AxiosResponse<CheckoutModel>> {
        try {
            return await axios.post("/api/checkout/placeorder", model, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async setDelivery(model: CheckoutModel): Promise<AxiosResponse<CheckoutModel>> {
        try {
            return await axios.put("/api/checkout/setDeliveryProvider", model, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async setPaymentProvider(model: CheckoutModel): Promise<AxiosResponse<CheckoutModel>> {
        try {
            return await axios.put("/api/checkout/setPaymentProvider", model, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async setShippingOption(model: CheckoutModel): Promise<AxiosResponse<CheckoutModel>> {
        try {
            return await axios.put("/api/klarnacheckout/setShippingOption", model, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async setCampaignCode(campaignModel: CampaignModel): Promise<AxiosResponse<CampaignModel>> {
        try {
            return await axios.put("/api/checkout/setCampaignCode", campaignModel, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getMiniCart(): Promise<AxiosResponse<Cart>> {
        try {
            return await axios.get("/api/cart", {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async addToCart(cartItem: AddOrUpdateCartItem): Promise<AxiosResponse<Cart>> {
        try {
            return await axios.post("/api/cart/add", cartItem, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async updateCart(cartItem: AddOrUpdateCartItem): Promise<AxiosResponse<Cart>> {
        try {
            return await axios.put("/api/cart/update", cartItem, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async reorderCart(cartItem: ReorderCart): Promise<AxiosResponse<Cart>> {
        try {
            return await axios.post("/api/cart/reorder", cartItem, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async placeOrder(cartItem: ReorderCart): Promise<AxiosResponse<Cart>> {
        try {
            return await axios.post("/api/checkout/placeorder", cartItem, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getProductPriceStock(request: ProductPriceStockRequest): Promise<AxiosResponse<ProductPriceStock>> {
        try {
            return await axios.post("/api/cart/productpricestock", request, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getCategory(categorySystemId: Guid, categoryShowRecursively: boolean, isHaven: boolean = false): Promise<AxiosResponse<CategoryModel>> {
        try {
            const uri = isHaven ? "/api/havenb2b/category" : "/api/category";

            return await axios.get(uri, {
                params: {
                    categorySystemId,
                    categoryShowRecursively,
                },
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getProducts(categorySystemId: Guid, page: number, categoryShowRecursively: boolean, sortBy: string, sortVariantsByPriority: boolean, isHaven: boolean = false): Promise<AxiosResponse<Product[]>> {
        try {
            const uri = isHaven ? "/api/havenb2b/category/getproducts" : "/api/category/getproducts";
            return await axios.get(uri, {
                params: {
                    categorySystemId,
                    page,
                    categoryShowRecursively,
                    sortBy,
                    sortVariantsByPriority,
                },
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async searchProducts(term: string, page: number, isHaven: boolean = false): Promise<AxiosResponse<SearchProductsResult>> {
        try {
            const uri = isHaven ? "/api/havenb2b/category/searchproducts" : "/api/category/searchproducts";
            return await axios.get(uri, {
                params: {
                    term,
                    page,
                },
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getProductDeliveryDate(request: ProductPriceStockRequest): Promise<AxiosResponse<ProductAvailableResponse>> {
        try {
            return await axios.post("/api/cart/productdeliverydate", request, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getDeliveryCheckouts(model: CheckoutQuery): Promise<AxiosResponse<any>> {
        try {
            return await axios.post("/transportapi/unifaun/getDeliveryCheckouts", model, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async searchB2cProducts(term: string): Promise<AxiosResponse<SearchProductsResult>> {
        try {
            return await axios.get("/api/b2csearch/searchproducts", {
                params: {
                    term,
                },
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async searchSpareParts(term: string, searchSpareparts: boolean): Promise<AxiosResponse<SearchSparePartResult>> {
        try {
            return await axios.get("/api/b2csearch/searchspareparts", {
                params: {
                    term,
                    searchSpareparts,
                },
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getProductSpareParts(variantId: string, sparePartCategorySystemId: Guid): Promise<AxiosResponse<ProductSparePartsResult>> {
        try {
            let sparePartCategoryId = sparePartCategorySystemId.toString();

            return await axios.get("/api/sparepartshop/getspareparts", {
                params: {
                    variantId,
                    sparePartCategoryId,
                },
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getSparePartsType(): Promise<AxiosResponse<SparePartsTypeResult>> {
        try {
            return await axios.get<SparePartsTypeResult>("/api/sparepartshop/sparepartstypes", {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getCommonProblems(): Promise<AxiosResponse<CommonProblemType>> {
        try {
            return await axios.get<CommonProblemType>("/api/sparepartshop/commonproblems", {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async searchSparePartsByType(selectedSparePartTypeCategoryId: Guid): Promise<AxiosResponse<SearchSparePartResult>> {
        try {
            return await axios.get("/api/b2csearch/searchsparepartsbytype", {
                params: {
                    selectedSparePartTypeCategoryId,
                },
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async saveCustomercalls(customerResponse: CustomerResponseModel): Promise<AxiosResponse<any>> {
        try {
            return await axios.post("/api/customerservice/savecustomercalls", customerResponse, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getCustomercallsReport(dataIdentifier: string, fromDate: Date, toDate: Date): Promise<AxiosResponse<any>> {
        try {
            return await axios.get("/api/customerservice/getcustomerservicereport", {
                params: {
                    dataIdentifier,
                    fromDate,
                    toDate,
                },
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
                responseType: "arraybuffer",
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async importExcelToCheckout(files: File): Promise<AxiosResponse<any>> {
        try {
            return await axios.post("/api/cart/importExcelToCheckout", files, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getSampleCheckoutExcelFile(): Promise<AxiosResponse<any>> {
        try {
            return await axios.get("/api/cart/getSampleCheckoutExcelFile", {
                responseType: "arraybuffer",
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async getJeevesPrices(request: JeevesPriceRequest): Promise<AxiosResponse<JeevesPriceResponse>> {
        try {
            return await axios.post("/api/cart/getjeevesprice", request, {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
    async selectCountry(selectedCountryId: string): Promise<AxiosResponse<any>> {
        try {
            return await axios.post("/api/countrypicker/post", '"' + selectedCountryId + '"', {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    "Content-Type": "application/json",
                },
            });
        } catch (e) {
            return errorResponse(e);
        }
    },
};
