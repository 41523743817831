import { ConfiguratorProduct } from "@/models/tapwell/Configurator";
import { Component, Prop, Vue } from "vue-property-decorator";
import ShowerConfigurator from "./ShowerConfigurator.vue";
import { dectectMobileDevice } from "@/scripts/shared/sharedScripts.js";
import { Guid } from "guid-typescript";

@Component({
    components : {
        ShowerConfigurator,
    },
    mounted(){
        detectStickyHeader('.configurator-page__step-header');
    }
})
export default class ShowerConfiguratorStandalone extends Vue {
    public translations: any;

    @Prop()
    public model!: ConfiguratorProduct[];

    @Prop()
    public showerconfiguratorpdfurl!: string;

    @Prop()
    public configuratoroptiontitle1!: string;

    @Prop()
    public configuratoroptiontitle2!: string;

    @Prop()
    public configuratoroptiontitle3!: string;
    
    @Prop()
    public configuratoroptiontitle4!: string;

    @Prop()
    public pagesystemid!: Guid;


    public configuratorOptionTitle1Internal!: string;

    get configuratoroptiontitle1internal(): string {
        return this.configuratorOptionTitle1Internal;
    }

    public configuratorOptionTitle2Internal!: string;

    get configuratoroptiontitle2internal(): string {
        return this.configuratorOptionTitle2Internal;
    }

    public configuratorOptionTitle3Internal!: string;

    get configuratoroptiontitle3internal(): string {
        return this.configuratorOptionTitle3Internal;
    }

    public configuratorOptionTitle4Internal!: string;

    get configuratoroptiontitle4internal(): string {
        return this.configuratorOptionTitle4Internal;
    }

    public pageSystemIdInternal!: Guid;

    get pagesystemidinternal(): Guid {
        return this.pageSystemIdInternal;
    }

    private requestContext: any;

    protected selectedVariantId: string = "";

    protected visibleConfigurator: boolean = false;

    protected reopenShowerConfigurator: boolean = false;

    constructor() {
        super();
        this.translations = window.__litium.translation;
        this.requestContext = window.__litium.requestContext;
        this.configuratorOptionTitle1Internal = this.configuratoroptiontitle1;
        this.configuratorOptionTitle2Internal = this.configuratoroptiontitle2;
        this.configuratorOptionTitle3Internal = this.configuratoroptiontitle3;
        this.configuratorOptionTitle4Internal = this.configuratoroptiontitle4;
        this.pageSystemIdInternal = this.pagesystemid;
    }

    selectProduct(variantId: string) {
        if(this.visibleConfigurator){
            this.reopenShowerConfigurator = !this.reopenShowerConfigurator;
        }

        this.selectedVariantId = variantId;
        this.visibleConfigurator = true;

        var element = this.$refs['step-2'] as HTMLElement;
        setTimeout(()=>{
            element.scrollIntoView();
        },100)
        
        detectStickyHeader('.configurator-page__step-header--step2');
    }
}



function detectStickyHeader(className){
    const element = document.querySelector(className)

    const observer = new IntersectionObserver( 
        ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
        {threshold: [1]}
    );

    observer.observe(element);
}
