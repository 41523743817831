import axios from "axios";
import { ImageBankFilterModel, ImageBankImageModel } from "./model";

export const ApiService = {
    async search(query: { search: string; color?: string[]; brand?: string[]; categories?: string[]; imageTypes?: string[]; pageIndex?: number }) {
        var result = await axios.post<ImageBankImageModel[]>(
            "/api/imagebank",
            {
                ...query,
            },
            {
                headers: {
                    "litium-request-context": JSON.stringify(window.__litium.requestContext),
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );

        return result;
    },

    async getFilters() {
        var result = await axios.get<ImageBankFilterModel>("/api/imagebank/filters", {
            headers: {
                "litium-request-context": JSON.stringify(window.__litium.requestContext),
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        return result;
    },

    async downloadImages(resolution: string, ids: string[]) {
        var result = await axios({
            method: "POST",
            url: "/api/imagebank/download",
            responseType: "arraybuffer",
            headers: {
                "litium-request-context": JSON.stringify(window.__litium.requestContext),
                Accept: "application/pdf",
            },
            data: {
                Resolution: resolution,
                Ids: ids,
            },
        });

        const url = window.URL.createObjectURL(new Blob([result.data], { type: "application/zip" }));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "images.zip");
        link.click();
    },
};
