import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ProductTileB2c from "../Product/ProductTileB2C.vue";
import { ProductStore } from "@/store/tapwell/productstate";
import { Product, SearchProductsResult, Variant } from "@/models/tapwell/Product";
import { ApiService } from "@/services/tapwell/ApiService";
import { CategoryNavigationItem } from "@/models/tapwell/Category";

@Component({
  components: {
    ProductTileB2c,
  },
})

export default class SearchModal extends Vue {

  @Prop()
  protected modalopen!: boolean;
  @Prop()
  protected searchpageurl!: string;

  protected infiniteId: any = "default";
  protected isLoading: boolean = false;
  protected noResult: boolean = false;

  private searchTerm: string = "";

  private executedSearchTerm: string = "";

  public translations: any;

  private searchWordInternal: string = "";

  private categorySearchResult!: CategoryNavigationItem[];

  set searchterminternal(value: string) {
    this.searchWordInternal = value;
  }

  get searchterminternal(): string{
    return this.searchWordInternal;
  }

  constructor() {
    super();

    this.translations = window.__litium.translation;
    this.categorySearchResult = [];
  }

  get searchresult(): Product[] {
    return ProductStore.searchresult;
  }

  @Watch("modalopen")
  setSearchInputFocus() {
    if (this.modalopen) {
      var input = this.$refs.search as HTMLInputElement;
      input.focus();
    }
  }

  @Watch("searchterminternal")
  public termChanged(newTerms: string, oldValue: string) {
    setTimeout(() => {
      if (this.executedSearchTerm == this.searchterminternal) {
        return;
      }
      
      this.executedSearchTerm = this.searchterminternal;
      this.isLoading = true;
      this.noResult = false;

      ApiService.searchB2cProducts(this.searchterminternal).then((response) => {
        if (response && response.data) {
          this.handlePageSearch(response.data);
        }
      }).finally(() => {
        this.isLoading = false;

        if (ProductStore.searchresult.length == 0) {
          this.noResult = true;
        }
      })
    }, 2000);
  }

  search(event: Event) {
    var input = event.target as HTMLInputElement;
    var term = input.value;

    ProductStore.saveSearchResult([] as Product[]);

    if (term.length <= 1) {
      return;
    }

    if(this.searchpageurl!=""){
      var currentlocation = this.searchpageurl+"?q="+term;
      window.history.pushState(null, "", currentlocation);
    }

    this.searchterminternal = term;
  }

  handlePageSearch(data: SearchProductsResult) {
    if (this.searchterminternal == data.term) {
      ProductStore.saveSearchResult(ProductStore.searchresult.concat(data.products));
      this.categorySearchResult = data.categories;
    }
  }

  selectVariant(product: Product, variant: Variant): void {
    document.location.href = variant.url;
  }

  toggleModal() {
    this.$emit('toggleFromModal');
  }

  productTileShouldBeHighlighted(index: number) {
    return index == 0 || (index + 1) % 14 == 0;
  }
}

