import { getStoreBuilder, BareActionContext } from "vuex-typex";
import { RootState } from "./index";

export interface SparePartShopState {
    selectedVariantId: string;
}

const initialState: SparePartShopState = {
    selectedVariantId: '',
};

const store = getStoreBuilder<RootState>().module("sparepartshop", initialState);

// private getters
function getStoreSelectedVariantId(state: SparePartShopState): string {
    return state.selectedVariantId;
}

// getters
const getStateSelectedVariantId = store.read(getStoreSelectedVariantId, "getStoreSelectedVariantId");

// mutations:
function mutateSelectedVariantId(state: SparePartShopState, selectedVariantId: string): Promise<void> {
    return new Promise((resolve, reject) => {
        state.selectedVariantId = selectedVariantId;
        resolve();
    });
}

// actions
async function saveSelectedVariantId(context: BareActionContext<SparePartShopState, RootState>, selectedVariantId: string): Promise<void> {
    return await mutateSelectedVariantId(context.state, selectedVariantId);
}

// export store
export const SparePartShopStore = {
    saveSelectedVariantId: store.dispatch(saveSelectedVariantId, "saveSelectedVariantId"),
    get selectedVariantId() { return getStateSelectedVariantId(); },
};
