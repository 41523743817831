window.addEventListener("DOMContentLoaded", (event) => {
    const hamburger = document.querySelector(".hamburger");
    const menu = document.querySelector(".header");
    const body = document.querySelector("body");
    const html = document.querySelector("html");
    const cart = document.querySelector(".header__cart");
    const heading = document.querySelector(".header__heading");
    const links = document.querySelector(".header__links");
    const app = document.querySelector("#app");
    
    const header = document.querySelector(".header");
    const bannerText = document.querySelector(".banner__text");
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    const searching = "searching";

    function displayWindowSize() {
        var windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        if (!windowWidth < 1024 && !app.classList.contains("b2c")) {
            hamburger.classList.remove("open");
            menu.classList.remove("open");
            body.classList.remove("menu-open");
            html.classList.remove("menu-open");
            menu.classList.remove("mega-menu-open");
            body.classList.remove("mega-menu-open");
            html.classList.remove("mega-menu-open");
        }
        if (windowWidth < 1024 && app.classList.contains("b2c")) {
            hamburger.classList.remove("open");
            menu.classList.remove("open");
            body.classList.remove("menu-open");
            html.classList.remove("menu-open");
            menu.classList.remove("mega-menu-open");
            body.classList.remove("mega-menu-open");
            html.classList.remove("mega-menu-open");
        }
    }

    function toggleMenuClick() {
        var windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        if (windowWidth < 1024) {
            hamburger.classList.toggle("open");
            menu.classList.toggle("open");
            body.classList.toggle("menu-open");
            html.classList.toggle("menu-open");
        } else if(!windowWidth < 1024 && app.classList.contains("b2c")) {
            hamburger.classList.toggle("open");
            menu.classList.toggle("mega-menu-open");
            body.classList.toggle("mega-menu-open");
            html.classList.toggle("mega-menu-open");
        }
        else {
            hamburger.classList.remove("open");
            body.classList.remove("menu-open");
            html.classList.remove("menu-open");
            cart.classList.remove("open");
            heading.classList.remove("open");
            links.classList.remove("open");
        }
    }

    window.addEventListener("resize", displayWindowSize);
    hamburger.addEventListener("click", toggleMenuClick);  

    let lastScroll = 0;

    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        //Top of page
        if (currentScroll <= 0) {
            if (header) {
                header.classList.remove(scrollUp);
                header.classList.remove(scrollDown);
                header.classList.remove("stick");
            }
            if (bannerText) {
                bannerText.classList.remove(scrollDown);     
            }
            return;
        }
        
        //Scroll down
        if (currentScroll > lastScroll && !header.classList.contains(scrollDown) && !header.classList.contains("open") && !body.classList.contains("product-category-page")) {
            if (header) {
                header.classList.add(scrollDown);
                header.classList.remove(scrollUp);
            }
            if (bannerText) {
                bannerText.classList.add(scrollDown);
            }

        } 

        // Edge case Product categoryPage
        else if (currentScroll < 350 && !header.classList.contains("open") && body.classList.contains("product-category-page")) {
            if (header) {
                header.classList.add("stick");
            }
        } 

        // Edge case Product categoryPage
        else if (currentScroll > 350 && !header.classList.contains("open") && body.classList.contains("product-category-page")) {
            if (header) {
                header.classList.remove(scrollUp);
                header.classList.add(scrollDown);
                header.classList.remove("stick");
            }
            if (bannerText) {
                bannerText.classList.remove(scrollUp);
                header.classList.add(scrollDown);
            }
        } 
        
        // Edge case Product categoryPage
        if (currentScroll < lastScroll && header.classList.contains(scrollDown) && !header.classList.contains("open") && body.classList.contains("product-category-page")) {
            if (header) {
                header.classList.remove(scrollDown);
                header.classList.add(scrollUp);
            }
            if (bannerText) {
                bannerText.classList.remove(scrollDown);
                header.classList.add(scrollUp);
            }
        }
        //Scroll up
        else if (currentScroll < lastScroll && header.classList.contains(scrollDown) && !header.classList.contains("open") && !header.classList.contains(searching)) {
            if (header) {
                header.classList.remove(scrollDown);
                header.classList.add(scrollUp);
            }
        }
        lastScroll = currentScroll;
    });

    function showMoreOrders(e) {
        e.target.parentNode.parentNode.parentNode.parentNode.classList.add("open");
    }
    var showMoreBtn = document.querySelectorAll(".order__show-btn");
    for (var i = 0; i < showMoreBtn.length; i++) {
        showMoreBtn[i].addEventListener("click", showMoreOrders); 
    }
});

// IE11 polyfill source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#polyfill
if (!Object.entries) {
    Object.entries = function( obj ){
      var ownProps = Object.keys( obj ),
          i = ownProps.length,
          resArray = new Array(i); // preallocate the Array
      while (i--)
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
  
      return resArray;
    };
}