import { Component, Prop } from "vue-property-decorator";
import ProductTile from "../Product/ProductTile";
import { SparePartShopStore } from "@/store/tapwell/sparepartshopstate";
import { Guid } from "guid-typescript";

@Component
export default class SelectProductTile extends ProductTile {
   
    requestContext: any;

    constructor() {
        super();

        this.requestContext = window.__litium.requestContext;
    }

    get productIsTall(): boolean {
        return this.product.highlight === 'Tall';
    }

    get thumb(): string {
        if (this.productIsTall) {
            return this.image;
        }

        if (this.selectedVariant.thumbs && this.selectedVariant.thumbs.length > 0) {
            return this.selectedVariant.thumbs[0];
        }
        return "";
    }

    get hasMultipleVariants() {
        return this.product.variants.length > 1;
    }

    get isActiveVariant() {
        return SparePartShopStore.selectedVariantId == this.selectedVariant.id;
    }

    showSpareParts() {
        sessionStorage.setItem('spare-parts-selected-variant', this.selectedVariant.id);
        this.$emit('showSparePartsForSelectedVariant', this.selectedVariant.id, Guid.createEmpty());
    }
}
