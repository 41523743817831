import { Vue, Component, Prop } from "vue-property-decorator";
import ProductAccessory from "./ProductAccessory.vue";
import { Product } from "@/models/tapwell/Product";

@Component({
    components: {
        ProductAccessory,
    },
})
export default class ProductAccessoryList extends Vue {
    @Prop()
    public title!: string;

    @Prop()
    public introduction!: string;

    @Prop()
    public products!: Product[];

    constructor() {
        super();
    }
}
