import Vue from "vue";
import Vuex, { Store } from "vuex";
import { getStoreBuilder } from "vuex-typex";

// Each module must be explicitly imported here
// so that they are built before the store is built.
import "./checkoutstate";
import { CheckoutState } from "./checkoutstate";
import "./productstate";
import { ProductState } from "./productstate";
import "./sparepartshopstate";
import { SparePartShopState } from "./sparepartshopstate";
import "./newsletterModal";
import { NewsletterState } from "./newsletterModal";
import "@/imagebank/imagebank-state";
import { ImageBankState } from "@/imagebank/imagebank-state";

export interface RootState {
    checkout: CheckoutState;
    product: ProductState;
    sparepartshop: SparePartShopState;
    newsletterModal: NewsletterState;
    imagebank: ImageBankState;
}

Vue.use(Vuex);

const store: Store<RootState> = getStoreBuilder<RootState>().vuexStore();
export default store;
