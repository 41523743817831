import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class Button extends Vue {
    @Prop({ default: "primary" })
    readonly type!: "primary" | "secondary";

    @Prop({ default: false })
    readonly disabled!: boolean;

    @Emit("click")
    onClick(e: MouseEvent) {
        return e;
    }
}
