import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { SelectOption } from "./SelectOption";

@Component
export default class Select extends Vue {
    @Prop()
    value!: number;

    @Prop()
    options!: Array<SelectOption>;

    isOpen = false;
    selected?: SelectOption = undefined;

    constructor() {
        super();

        if (this.value) {
            const result = this.options.find(x => x.id === this.value);
            this.selected = result ? result : undefined;
        }

        if (!this.selected) {
            this.selected = this.options[0];
        }
    }

    @Emit("input")
    mounted() {
        return this.selected;
    }

    @Watch("selected")
    @Emit("input")
    onSelected() {
        this.isOpen = false;
        return this.selected;
    }
}

