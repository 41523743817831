import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class Search extends Vue {
    @Prop({ default: "Search" })
    placeholder!: string;

    @Prop({ default: "" })
    value!: string;

    @Emit("input")
    onInput(e: Event) {
        return (e.target as HTMLInputElement).value;
    }
}
