import { Component, Vue, Prop } from "vue-property-decorator";
import { Product } from "@/models/tapwell/Product";

@Component
export default class ProductUpSell extends Vue {
    public translations: any;

    @Prop()
    public products!: Product[];

    @Prop()
    public title!: string;

    @Prop()
    public introduction!: string;

    constructor() {
        super();
        this.translations = window.__litium.translation;
    }
}
