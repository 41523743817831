import { Component, Prop, Vue } from "vue-property-decorator";
import { ProductStore } from "@/store/tapwell/productstate";

@Component
export default class ProductSearch extends Vue {
    public translations: any;
    private isActive: boolean = false;
    public shown: boolean = false;
    @Prop()
    private searchWord!: string;


    constructor() {
        super();
        this.translations = window.__litium.translation;

    }

    mounted(){
        var input = this.$refs.search as HTMLInputElement;

        if(this.searchWord!=undefined)
        {
            input.value = this.searchWord;
        }
    }

    listHeight() {
        var productList: HTMLElement = document.getElementById('productList') !;
        productList.style.minHeight = productList.offsetHeight + 'px;'
        var header = <HTMLElement>document.querySelector(".header");
      const searching = "searching";
            this.shown = true;
            if (this.shown == true) {
                header.classList.add(searching);
            }
            else {
                header.classList.remove(searching);
            } 

    }
    search(event: Event) {
        var input = event.target as HTMLInputElement;
        var value = input.value;

        this.$emit("performSearch", value); 
    }

    loadCategoryIfEmptySearch(event: Event) {
        var input = event.target as HTMLInputElement;
        var value = input.value;
        var searchInput = <HTMLElement>document.querySelector(".product-search__input");
        const focus = "focus";
 
        if (!value || value.length == 0) {
            this.$emit("resetSearch", ProductStore.currentCategoryId);
            var header = <HTMLElement>document.querySelector(".header");
            const searching = "searching";
            searchInput.classList.remove(focus);
           header.classList.remove(searching); 
        }
    }
    searchActive() {
        this.isActive = !this.isActive;
        var input = this.$refs.search as HTMLInputElement;
        input.focus();
        
    }
}
