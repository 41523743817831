export interface ConfiguratorModel {
    configuratorVariants: ConfiguratorVariant[];
}

export interface ConfiguratorProduct {
    id: string;
    name: string;
    selected: boolean;
    image: string;
}

export interface ConfiguratorVariant {
    id: string;
    name: string;
    price: number;
    displayPrice: string;
    selected: boolean;
    image: string;
    url: string;
    currencySymbol: string;
    configuratorParts: ConfiguratorPart[];
}

export interface ConfiguratorPart {
    id: string;
    value: string;
    price: number;
    partType: ConfiguratorPartType;
    image: string;
    selected: boolean;
    displayPrice: string;
    currencySymbol: string;
}

export enum ConfiguratorPartType {
    Surface = 0,
    ShowerHeadRound = 1,
    ShowerHeadRectangular = 2,
    ShowerHeadSquare = 3,
    ShowerHeadFlush = 4,
    ShowerArmWall = 5,
    ShowerArmCeiling = 6,
    Other = 7,
    ExtraPart = 8
}

export enum ConfiguratorMode{
    BuiltIn = 'builtIn',
    StandAlone = 'standAlone',
}
