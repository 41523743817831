import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ApiService } from "@/services/tapwell/ApiService";
import { SearchSparePartResult } from "@/models/tapwell/Product";
import { Guid } from "guid-typescript";
@Component
export default class SearchPanel extends Vue {
    public translations: any;
    private searchTabActive: boolean = false;
    
    @Prop()
    public searchTermFromUrl!: string;
    @Prop()
    public searchSparePartsFromUrl!: boolean;
    @Prop()
    public selectedFilterSparePartByTypeFromUrl!: Guid;
    @Prop()
    public selectedFilterSparePartByCommonProblemFromUrl!: Guid;
    @Prop()
    public selectedQueryStringLoad!: boolean;
    private searchTerm: string = "";

    private isSearching: boolean = false;

    private sparePartsTypes: any = [];
    private commonProblems: any = [];
	private showMenu: boolean=false;
    private selectedFilterSparePartByType: Guid = Guid.createEmpty();
    private selectedFilterSparePartByCommonProblem: Guid = Guid.createEmpty();
    private productType: string = "product";
    private selectedTypeText: string = "";
	private searchSpareparts: boolean=false;

    constructor() {
        super();
        this.translations = window.__litium.translation;
        this.selectedTypeText = this.translations["sparepartshop.myproduct"];
    }

    @Watch("searchTerm")
    public termsChanged(newTerm: string, oldValue: string) {
        this.searchTerm=newTerm;
        this.searchTabActive = true;
        if (newTerm.length == 0) {
            this.$emit('getClearSearchResult');
            this.$emit('getClearSparePartFilter');
            this.searchTabActive = false;
            return;
        } else if (newTerm.length < 3) {
            return;
        }
        if (this.isSearching) {
            return;
        }
    }

    search(event: Event) {
        this.searchTabActive = true;
        var term = this.searchTerm;

        if (term.length == 0) {
            this.$emit('getClearSearchResult');
            this.$emit('getClearSparePartFilter');
            this.searchTabActive = false;
            return;
        }
        if (term.length < 3) {
            return;
        }
     
        if (this.isSearching) {
            return;
        }
        
        sessionStorage.setItem('spare-parts-search-term', this.searchTerm);
        sessionStorage.setItem('spare-parts-selected-variant', '');
        this.isSearching = true;

        if(this.productType=="sparepart") {
            this.searchSpareparts = true;
        } else {
            this.searchSpareparts = false;
        }

        this.$emit("setSparePartFilter", this.searchSpareparts, this.searchTerm,Guid.createEmpty(),Guid.createEmpty(),'');

        ApiService.searchSpareParts(this.searchTerm,this.searchSpareparts).then((response) => {
            if (response && response.data) {
                this.handleSearchResult(response.data);
            }
            else
            {
                this.isSearching = false;
            }
        });
    }

    toggleShow() {
        this.showMenu = !this.showMenu;
    }

    itemClicked (item,text) {
        this.toggleShow();
        this.productType=item;       
        this.selectedTypeText=text;
    }

    handleSearchResult(data: SearchSparePartResult) {
        this.$emit('getSearchResult', data, this.productType);
        this.isSearching = false;
    }

    loadSparePartsTypes(){
        ApiService.getSparePartsType().then((response) => {
            if (response && response.data) {
                this.sparePartsTypes = response.data;
            }
        });
    }

    loadCommonProblems(){
        ApiService.getCommonProblems().then((response) => {
            if (response && response.data) {
                this.commonProblems = response.data;
            }
        });
    }

    searchBySparePartType(selectedSparePartTypeCategoryId:Guid){
        this.isSearching = true;
        this.searchTerm = '';
        sessionStorage.setItem('spare-parts-search-term', this.searchTerm);
        this.searchTabActive = false;
        this.selectedFilterSparePartByCommonProblem = Guid.createEmpty();

        if(this.selectedQueryStringLoad){
            this.selectedFilterSparePartByType = selectedSparePartTypeCategoryId;
        }
        else{
            if(this.selectedFilterSparePartByType === selectedSparePartTypeCategoryId){
                this.selectedFilterSparePartByType = Guid.createEmpty();
            }
            else{
                this.selectedFilterSparePartByType = selectedSparePartTypeCategoryId;
            }
        }

        this.$emit("setSparePartFilter", this.searchSpareparts, this.searchTerm,this.selectedFilterSparePartByType,this.selectedFilterSparePartByCommonProblem,'');

        if(!this.selectedFilterSparePartByType.isEmpty){
            ApiService.searchSparePartsByType(this.selectedFilterSparePartByType).then((response) => {
                if (response && response.data) {
                    this.handleSearchResult(response.data);
                }
            });
        }
        else{
            this.$emit('getClearSearchResult');
            this.isSearching=false;
        }
    }

    searchBySparePartCommonProblem(selectedSparePartTypeCommonProblemId:Guid){
        this.isSearching = true;
        this.searchTerm = '';
        sessionStorage.setItem('spare-parts-search-term', this.searchTerm);
        this.searchTabActive = false;
        this.selectedFilterSparePartByType =  Guid.createEmpty();

        if(this.selectedQueryStringLoad){
            this.selectedFilterSparePartByCommonProblem = selectedSparePartTypeCommonProblemId;
        }
        else{
            if(this.selectedFilterSparePartByCommonProblem === selectedSparePartTypeCommonProblemId){
                this.selectedFilterSparePartByCommonProblem = Guid.createEmpty();
            }
            else{
                this.selectedFilterSparePartByCommonProblem = selectedSparePartTypeCommonProblemId;
            }
        }

        this.$emit("setSparePartFilter", this.searchSpareparts, this.searchTerm,this.selectedFilterSparePartByType,this.selectedFilterSparePartByCommonProblem,'');

        if(!this.selectedFilterSparePartByCommonProblem.isEmpty){
            ApiService.searchSparePartsByType(this.selectedFilterSparePartByCommonProblem).then((response) => {
                if (response && response.data) {
                    this.handleSearchResult(response.data);
                }
            });
        }
        else{
            this.$emit('getClearSearchResult');
            this.isSearching=false;
        }
    }

    showToolTip(id){
        this.hideAllToolTip();

        var toolTip = document.getElementById(id) ;
        if(toolTip){
            (<HTMLElement>toolTip).style.display = 'block';
        }
    }

    hideAllToolTip(){
        var toolTips = document.getElementsByClassName('tool-tip');
        if (toolTips && toolTips.length > 0){
            for(var i=0; i < toolTips.length; i++){
                (<HTMLElement>toolTips[i]).style.display = "none";
            }
        } 
    }

    mounted() {
        this.loadSparePartsTypes();
        this.loadCommonProblems();
    }
    
    created(){

        this.searchTerm = this.searchTermFromUrl;
        this.searchSpareparts = this.searchSparePartsFromUrl;
        this.searchTabActive = true;

        if (this.searchTerm.length == 0) {
            this.searchTabActive = false;
        }
        
        sessionStorage.setItem('spare-parts-search-term', this.searchTerm);
        sessionStorage.setItem('spare-parts-selected-variant', '');
        this.isSearching = true;
        if(this.searchSpareparts && this.searchSpareparts==true){

            this.productType="sparepart";       
            this.selectedTypeText=this.translations["sparepartshop.sparepartsoraccessories"];
        }
        else{
            this.productType="product";       
            this.selectedTypeText=this.translations["sparepartshop.myproduct"];
        }

        if(this.searchTerm!=''){
            ApiService.searchSpareParts(this.searchTerm,this.searchSpareparts).then((response) => {
                if (response && response.data) {
                    this.handleSearchResult(response.data);
                }
                else
                {
                    this.isSearching = false;
                }
            });
        }
        else if(!this.selectedFilterSparePartByTypeFromUrl.isEmpty){
            this.searchBySparePartType(this.selectedFilterSparePartByTypeFromUrl);
        }
        else if(!this.selectedFilterSparePartByCommonProblemFromUrl.isEmpty)
        {
            this.searchBySparePartCommonProblem(this.selectedFilterSparePartByCommonProblemFromUrl);
        }
        else{
            this.isSearching = false;
        }
    }
}