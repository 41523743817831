import { ConfiguratorModel } from "@/models/tapwell/Configurator";
import axios, { AxiosResponse } from "axios";
import { Guid } from "guid-typescript";

export const ConfiguratorClient =  {
  async getShowerConfiguratorModel(variantId: string, pageSystemId: Guid): Promise<AxiosResponse<ConfiguratorModel>> {
        return await axios.get("/api/showerconfigurator", {
          params: {
            variantId,
            pageSystemId
          },
          headers: {
            "litium-request-context": JSON.stringify(window.__litium.requestContext),
          }
      })
  },
}
