import { Component, Prop, Vue } from "vue-property-decorator";
import { ApiService } from "@/services/tapwell/ApiService";
import { Country } from "@/models/tapwell/Country";

@Component
export default class CountryPicker extends Vue {
    @Prop()
    protected model!: Country;
    protected open: boolean = false;
    @Prop()
    protected selectedcountryid!: string;
    private currentCountryCodeInternal!: string;
    
    constructor() {
        super();

        this.currentCountryCode = this.model[this.selectedcountryid];
    }
    
    get currentCountryCode(): string {
        return this.currentCountryCodeInternal;
    }

    set currentCountryCode(value: string){
        this.currentCountryCodeInternal = value;
    }

    protected chooseCountry(id: string){
        this.currentCountryCode = this.model[id];

        ApiService.selectCountry(id).then((response) => {
            location.reload();
        });
    }
}
