import { render, staticRenderFns } from "./ProductTileHaven.vue?vue&type=template&id=2fbdfb12&scoped=true&"
import script from "./ProductTileHaven.ts?vue&type=script&lang=ts&"
export * from "./ProductTileHaven.ts?vue&type=script&lang=ts&"
import style0 from "./ProductTileHaven.vue?vue&type=style&index=0&id=2fbdfb12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fbdfb12",
  null
  
)

export default component.exports