export default {
    name: "ThankYouModal",
    props: ["thankYouMsg", "thankYouHeader"],
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {

    }
};