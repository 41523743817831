import { Component, Prop, Vue } from "vue-property-decorator";
import { CategoryNavigationItem } from "@/models/tapwell/Category";
import { ProductStore } from "@/store/tapwell/productstate";
import { Product } from "@/models/tapwell/Product";

@Component
export default class CategoryNavigation extends Vue {
    public translations: any;

    @Prop()
    protected navigationitems!: CategoryNavigationItem[];

    private showNavigation: boolean;

    constructor() {
        super();
        this.translations = window.__litium.translation;
        this.showNavigation = false;
    }

    private trackPageView(categoryUrl: string): void {
        this.$gtm.trackView("CategoryNavigation", categoryUrl);
    }
 
    private navigate(selectedCategory: CategoryNavigationItem, e: Event): void {
        e.preventDefault();
        this.trackPageView(selectedCategory.url);
        window.history.pushState(null, "", selectedCategory.url);
        document.title = selectedCategory.name;
        var searchInput = <HTMLInputElement>document.querySelector(".product-search__input");
        var productList: HTMLElement = document.getElementById('productList') !;
        const focus = "focus";

        this.updateNavigation(selectedCategory);
        ProductStore.saveCurrentCategoryId(selectedCategory.systemId);
        ProductStore.saveProducts([] as Product[]);
        this.$emit("navigateToCategory", selectedCategory.systemId);

        searchInput.classList.remove(focus);
        searchInput.value = "";
        productList.style.minHeight = productList.offsetHeight +"px";
    }

    private updateNavigation(selectedCategory: CategoryNavigationItem): void {
        for (const category of this.navigationitems) {
            if (category.systemId === selectedCategory.systemId) {
                category.selected = true;
            } else {
                category.selected = false;
            }

            for (const child of category.children) {
                if (child.systemId === selectedCategory.systemId) {
                    category.selected = true;
                    child.selected = true;
                } else {
                    child.selected = false;
                }
            }
        }
    }
}
