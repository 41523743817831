export function dectectMobileDevice(maxWidth) {      
    return  window.matchMedia(`only screen and (max-width: ${maxWidth}px)`).matches;
 }

 export function toggleBodyClassName(className){
    const bodyElem = document.body;

    if(bodyElem.classList.contains(className)){
        bodyElem.classList.remove(className);
    }else{
        bodyElem.classList.add(className)
    }
}