import { Component, Vue, Prop } from "vue-property-decorator";
import { Swiper, SwiperItem } from "vue-h5-swiper";
import { Parallax } from "@/models/tapwell/Blocks";

@Component({
    components: {
        Swiper,
        SwiperItem,
    },
})

export default class ParallaxBlock extends Vue {
    protected showHeaderInLightMode: boolean = false;
    @Prop()
    protected viewmodel!: Parallax;
    protected PAGE_TRANSITIONS = [
        'move',
        'fade',
        'cover',
        'carousel',
        'scale',
        'glue',
        'cube',
        'push',
        'three',
    ];

    constructor() {
        super();
    }
    public afterChange() {
        var header = <HTMLElement>document.querySelector(".header");
        var sliderItemActive = <HTMLElement>document.querySelector(".swiper-item.active");
        var lightMode = "lightmode";

        if (sliderItemActive.classList.contains(lightMode)) {
            header.classList.add(lightMode);

        } else {
            header.classList.remove(lightMode);

        }
    }

    public beforeChange(activeIndex: any) {
        var nextVideo = <HTMLElement>document.querySelector(".parallaxvideo-block__video--" + activeIndex);
        if (nextVideo != null) {
            var video = nextVideo.getElementsByTagName('video');
            if (video != null) {
                video[0].currentTime = 0; // RESET VIDEO
            }
        }
    }


} 
