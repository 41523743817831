import { Component, Prop } from "vue-property-decorator";
import { Product, Variant } from "@/models/tapwell/Product";
import ProductTile from "./ProductTile";

@Component
export default class ProductTileB2C extends ProductTile {
    @Prop()
    protected searchMode!: boolean;

    @Prop()
    protected isHighLightedFromSearch!: boolean;
  

    constructor() {
        super();    
        this.requestContext = window.__litium.requestContext;
    }
    get isInSearchMode(): boolean {
        if (this.searchMode) {
            return this.searchMode;
        }
        return false;
    }

    get productIsHighlighted(): boolean {
        if (this.isHighLightedFromSearch) {
            return true
        }

        return this.product.highlight === 'Highlight';
    }

    get productContentIsHighlighted(): boolean{
        if (this.isHighLightedFromSearch) {
            return true
        }

        return this.product.content.highlight === 'Highlight';
    }

    get productIsTall(): boolean {
        return this.product.highlight === 'Tall';
    }

    get productContentIsTall(): boolean {
        return this.product.content.highlight === 'Tall';
    }


    get isContentProduct(): boolean {
        return (this.product.content.editor != null 
            || this.product.content.desktopImage != null 
            || this.product.content.mobileImage != null
            || this.product.content.url != null)        
    }

    get thumb(): string {
        if (this.productIsHighlighted || this.productIsTall) {
            return this.image;
        }

        if (this.selectedVariant.thumbs && this.selectedVariant.thumbs.length > 0) {
            return this.selectedVariant.thumbs[0];
        }
        return "";
    }

    get showprice(): boolean {
        return !this.product.hidePrice && this.requestContext.showPrices
    }

    public goToVariant(product: Product, variant: Variant) :void {
        this.$emit('goToVariant', product, variant);
    }

    public goToShowerConfig(product: Product, variant: Variant) :void {
        this.$emit('goToShowerConfig', product, variant);
    }
}
