import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Button, Checkbox } from "@/imagebank/components";
import { imageBankStore } from "@/imagebank/imagebank-state";
import { ImageBankImageModel } from "@/imagebank/model";
import { IModal } from "@/imagebank/components/modal";
import Modal from "@/imagebank/components/modal/Modal.vue";

@Component({
    components: {
        "v-button": Button,
        "v-checkbox": Checkbox,
        "v-modal": Modal,
    },
})
export default class ImageModal extends Vue {
    public readonly $refs!: Vue["$refs"] & {
        modal: IModal;
    };

    @Prop({ default: false })
    show: boolean = false;

    @Prop({ default: undefined })
    image!: ImageBankImageModel;

    translations: any;

    constructor() {
        super();
        this.translations = window.__litium.translation;
    }

    get selected() {
        return !!imageBankStore.selectedImages.find(x => x.articleNumber === this.image.articleNumber);
    }

    @Watch("show")
    onShowChange() {
        if (this.image && this.show) {
            this.$refs.modal.openModal();
        }
    }

    @Emit("close")
    onModalClose() {
        return false;
    }

    @Emit("download")
    onDownload(resolution: "high" | "low") {
        return {
            resolution,
            ids: [this.image.id],
        };
    }

    @Emit("toggle-selection")
    toggleSelection() {
        return this.image;
    }
}
