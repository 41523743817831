import { getCookie, setCookie } from "@/services/tapwell/Cookies";
import { newsletterStore } from "@/store/tapwell/newsletterModal";

export default {
    name: "NewsletterSignup",
    props: ["model"],
    data() {
        return {
            signUpForNewsletter: false,
            termsAndConditions: false,
            email: '',
        };
    },
    computed: {
        translations(){
            return window.__litium.translation;
        },
        showModal(){
            return newsletterStore.showModal;
        }
    },
    methods: {
        closeModal() {
            newsletterStore.saveUserState({showNewsletterModal: false});
            setCookie("hidenewsletter", true, 7);
        },
        onSubmitBtnClick(){
            // For some reason this is needed 
            // to prevent pageload when submitting the form
        }
    },

    mounted() {
        setTimeout(() => {
            const hideNewsletter = getCookie("hidenewsletter");
            if (!hideNewsletter) {
                newsletterStore.saveUserState({showNewsletterModal: true});
            }
        }, 20000)
    }

};