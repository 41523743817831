import { Component, Emit, Vue } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
    show: boolean = false;

    openModal() {
        this.show = true;
    }

    @Emit("close")
    onCloseModal() {
        this.show = false;
        return this.show;
    }
}
