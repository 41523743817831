import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OrderConfirmationB2C extends Vue {
    @Prop()
    protected klarnahtmlsnippet!: string;

    private klarnaHtmlSnippetInternal!: string;

    get klarnahtmlsnippetinternal(): string {
        return this.klarnaHtmlSnippetInternal;
    }

    set klarnahtmlsnippetinternal(value: string) {
        this.klarnaHtmlSnippetInternal = value;
    }

    constructor() {
        super();
        this.klarnahtmlsnippetinternal = this.klarnahtmlsnippet;

        var localStorageCampaignCode = window.localStorage.getItem("campaignCode");
        if(localStorageCampaignCode!=null){
            localStorage.removeItem("campaignCode");
        }
    }

    mounted() {
        var klarnaOrdeConfirmationContainer = document.getElementById('klarna-order-confirmation-widget');
        if (klarnaOrdeConfirmationContainer) {
            klarnaOrdeConfirmationContainer.innerHTML = this.klarnahtmlsnippetinternal;
            var scriptsTags = klarnaOrdeConfirmationContainer.getElementsByTagName('script')
            for (var i = 0; i < scriptsTags.length; i++) {
                var parentNode = scriptsTags[i].parentNode
                var newScriptTag = document.createElement('script')
                newScriptTag.type = 'text/javascript'
                newScriptTag.text = scriptsTags[i].text
                if (parentNode) {
                    parentNode.removeChild(scriptsTags[i])
                    parentNode.appendChild(newScriptTag)
                }
            }
        }
    }
}