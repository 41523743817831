import { Component } from "vue-property-decorator";
import { Product, Variant } from "@/models/tapwell/Product";
import ProductTile from "../Product/ProductTile";

@Component
export default class SparePartTile extends ProductTile {
    requestContext: any;

    constructor() {
        super();

        this.requestContext = window.__litium.requestContext;
    }

    get shopenabled(): boolean {
        if(this.requestContext.enableEcommerce==='on')
        {
            return true;
        }

        if (this.requestContext.enableEcommerce==='sparePartOnly' && this.product.isSparePart) {
            return true;
        }

        return false;
    }    

    get productIsTall(): boolean {
        return this.product.highlight === 'Tall';
    }

    get thumb(): string {
        if (this.productIsTall) {
            return this.image;
        }

        if (this.selectedVariant.thumbs && this.selectedVariant.thumbs.length > 0) {
            return this.selectedVariant.thumbs[0];
        }
        return "";
    }

    public goToVariant(variant: Variant) :void {
        document.location.href = variant.url;
    }
}
