import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AddOrUpdateCartItem, OrderRow, ProductPriceStockRequest, JeevesPriceRequest, JeevesPriceItem } from "@/models/tapwell/Cart";
import { CheckoutStore } from "@/store/tapwell/checkoutstate";
import { Spinner } from "spin.js";
import { ApiService } from "@/services/tapwell/ApiService";
import { Product, Variant } from "../../../models/haven-b2b/Product";

@Component
export default class ProductTileHaven extends Vue {
    @Prop()
    public product!: Product;
    @Prop()
    public colorFieldValue!: string;
    public translations: any;

    public selectedVariant: Variant;
    public spinner: Spinner;
    public showMoreActive: boolean = false;
    public quantityChangedTriggered: boolean = false;
    public selectedVariantJeevesPrices: JeevesPriceItem[] = [];
    public requestContext: any;
    public showMaxLimitMessage: boolean = false;

    protected selectedTop: Variant;
    protected tops: { [key: string]: Variant } = {};
    protected alternatives: { [key: string]: Variant } = {};

    public get orderRow() {
        return CheckoutStore.cart.orderRows && CheckoutStore.cart.orderRows.find(row => row.articleNumber === this.selectedVariant.id);
    }

    constructor() {
        super();
        this.spinner = new Spinner({ scale: 0.5 });
        if (this.product.selectedVariant) {
            this.selectedVariant = this.product.selectedVariant;
        } else {
            this.selectedVariant = this.product.variants[0];
        }
        this.selectedVariantJeevesPrices = [];

        this.translations = window.__litium.translation;
        this.requestContext = window.__litium.requestContext;

        const tops = this.buildTops(this.product);
        this.tops = tops.tops;
        this.selectedTop = tops.top;

        const alternatives = this.buildAlternatives(this.product, this.selectedTop);
        this.alternatives = alternatives.alternatives;
        this.selectedVariant = alternatives.alternative;
    }

    public get thumb(): string {
        if (this.selectedVariant.thumbs && this.selectedVariant.thumbs.length > 0) {
            return this.selectedVariant.thumbs[0];
        }
        return "";
    }

    public get image(): string {
        if (this.selectedVariant.images && this.selectedVariant.images.length > 0) {
            return this.selectedVariant.images[0];
        }
        return "";
    }

    public get shopenabled(): boolean {
        if (this.requestContext.enableEcommerce === "on") {
            return true;
        }

        if (this.requestContext.enableEcommerce === "sparePartOnly" && this.product.isSparePart) {
            return true;
        }

        return false;
    }

    public get visibleVariants(): Variant[] {
        return this.product.variants.filter(x => !x.hideFromVariantSelector);
    }

    @Watch("orderRow")
    public onOrderRowChanged(orderRow: OrderRow) {
        if (orderRow) {
            this.selectedVariant.rowSystemId = orderRow.rowSystemId;
            this.selectedVariant.quantity = orderRow.quantity;
            this.getProductPriceAndStock(this.selectedVariant, 0);
            this.checkMaximumQuantitySize();
        }
    }

    public mounted() {
        if (!this.selectedVariant.inStock && this.shopenabled) {
            this.getProductDeliveryDate();
        }
    }

    @Watch("product")
    public setVariant() {
        this.select(this.product.variants[0]);
    }

    public select(variant: Variant): void {
        this.selectedVariant = variant;
        this.getProductPriceAndStock(this.selectedVariant, 0);
    }

    public decreaseAmount(): void {
        this.selectedVariant.quantity -= 1;
        if (this.selectedVariant.quantity < 0) {
            this.selectedVariant.quantity = 0;
        }
        this.checkQuantitySize();
        this.checkMaximumQuantitySize();
        this.getProductPriceAndStock(this.selectedVariant, 1500);
    }

    public increaseAmount(): void {
        this.selectedVariant.quantity += 1;
        this.checkQuantitySize();
        this.checkMaximumQuantitySize();
        this.getProductPriceAndStock(this.selectedVariant, 1500);
    }

    private checkQuantitySize(): void {
        if (this.selectedVariant.quantity > 99) {
            this.selectedVariant.quantityLarge = true;
        } else {
            this.selectedVariant.quantityLarge = false;
        }
    }
    private checkMaximumQuantitySize(): void {
        if (parseInt(this.selectedVariant.maximumBuyableQuantitiy) > 0 && this.selectedVariant.quantity >= parseInt(this.selectedVariant.maximumBuyableQuantitiy)) {
            this.showMaxLimitMessage = true;
        } else {
            this.showMaxLimitMessage = false;
        }
    }

    public getProductDeliveryDate(): void {
        const payload: ProductPriceStockRequest = {
            quantity: this.selectedVariant.quantity,
            variantId: this.selectedVariant.id,
            checkLitiumInventory: true,
        };
        ApiService.getProductDeliveryDate(payload).then(response => {
            if (response && response.data) {
                this.selectedVariant.stockStatus = response.data.formattedDateAvailable;
                this.selectedVariant.inStock = response.data.inStock;
            }
        });
    }

    public getJeevesPrices(): void {
        let quantities = "3;5;10";

        var productListElem = document.getElementById("productList");
        if (productListElem) {
            Array.prototype.forEach.call(productListElem.getElementsByClassName("tool-tip"), function(el) {
                el.style.display = "none";
            });
        }

        const payload: JeevesPriceRequest = {
            quantities: quantities,
            variantId: this.selectedVariant.id,
        };

        ApiService.getJeevesPrices(payload).then(response => {
            if (response && response.data && response.data.prices) {
                var self = this;
                self.selectedVariantJeevesPrices = response.data.prices;
                let elem = self.$el as HTMLElement;
                var toolTip = elem.getElementsByClassName("tool-tip");
                if (toolTip && toolTip.length > 0) {
                    (<HTMLElement>toolTip[0]).style.display = "block";
                }
            }
        });
    }

    public getProductPriceAndStock(variant: Variant, requestdelay: number): void {
        if (!this.quantityChangedTriggered) {
            this.spinner.spin(this.$el as HTMLElement);
            this.quantityChangedTriggered = true;
            var self = this;
            setTimeout(function() {
                const payload: ProductPriceStockRequest = {
                    quantity: variant.quantity,
                    variantId: variant.id,
                    checkLitiumInventory: false,
                };
                ApiService.getProductPriceStock(payload).then(response => {
                    if (response && response.data) {
                        let data = response.data;
                        variant.inStock = data.inStock;
                        variant.discountPercent = data.discountPercent;
                        variant.displayDiscountPrice = data.displayDiscountPrice;
                        variant.displayPrice = data.displayPrice;
                        variant.stockStatus = data.dateAvailable;
                    }
                });
                self.quantityChangedTriggered = false;
                self.spinner.stop();
            }, requestdelay);
        }
    }

    public addToCart(): void {
        this.spinner.spin(this.$el as HTMLElement);
        const payload: AddOrUpdateCartItem = {
            articleNumber: this.selectedVariant.id,
            quantity: this.selectedVariant.quantity,
            rowSystemId: this.selectedVariant.rowSystemId,
            comment: "",
        };
        var header = <HTMLElement>document.querySelector(".header");
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        const currentScroll = window.pageYOffset;

        if (currentScroll == 0) {
            header.classList.remove(scrollUp);
            header.classList.remove(scrollDown);
        } else {
            header.classList.add(scrollUp);
            header.classList.remove(scrollDown);
        }

        CheckoutStore.updateState(payload).then(() => {
            this.spinner.stop();
        });
    }

    public selectTop(top: Variant): void {
        this.selectedTop = top;

        const result = this.buildAlternatives(this.product, top);

        this.alternatives = result.alternatives;
        this.selectedVariant = result.alternative;

        this.getProductPriceAndStock(this.selectedVariant, 0);
    }

    buildTops = (product: Product) => {
        const items: { [key: string]: Variant } = {};

        for (const variant of product.variants) {
            items[variant.top] = variant;
        }

        const tops = this.sortDictionary(items);
        const top = Object.values(tops)[0];

        return { tops, top };
    };

    buildAlternatives = (product: Product, top: Variant) => {
        const items: { [key: string]: Variant } = {};

        for (const variant of product.variants) {
            if (variant.top !== top.top) continue;

            items[variant.alternative] = variant;
        }

        const alternatives = this.sortDictionary(items);
        const alternative = Object.values(alternatives)[0];

        return { alternatives, alternative };
    };

    sortDictionary = <T extends {} & object>(item: T): T => {
        return Object.keys(item)
            .sort()
            .reduce((acc, x) => {
                acc[x] = item[x];
                return acc;
            }, {}) as T;
    };
}
