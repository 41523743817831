<template>
  <div class="scroll-shadow__wrap" :class="{'scroll-shadow--disabled': disabled}">
    <div
      class="scroll-shadow__scroll-container"
      :style="{ width, height }"
      ref="scrollContainer"
      @scroll.passive="toggleShadow"
    >
      <slot />
      <span
        :class="{ 'is-active': shadow.top }"
        class="scroll-shadow__shadow-top"
      />
      <span
        :class="{ 'is-active': shadow.right }"
        class="scroll-shadow__shadow-right"
      />
      <span
        :class="{ 'is-active': shadow.bottom }"
        class="scroll-shadow__shadow-bottom"
      />
      <span
        :class="{ 'is-active': shadow.left }"
        class="scroll-shadow__shadow-left"
      />
    </div>
  </div>
</template>

<script>
function newResizeObserver(callback) {
  // Skip this feature for browsers which
  // do not support ResizeObserver.
  // https://caniuse.com/#search=resizeobserver
  if (typeof ResizeObserver === "undefined") return;

  return new ResizeObserver((e) => e.map(callback));
}

export default {
  name: "ScrollShadow",
  props: ["disabled"],
  data() {
    return {
      width: undefined,
      height: undefined,
      shadow: {
        top: false,
        right: false,
        bottom: false,
        left: false,
      },
    };
  },
  mounted() {
    if (!this.disabled) {
      // Check if shadows are necessary after the element is resized.
      const scrollContainerObserver = newResizeObserver(this.toggleShadow);
      if (scrollContainerObserver) {
        scrollContainerObserver.observe(this.$refs.scrollContainer);
        // Cleanup when the component is destroyed.
        this.$once("hook:destroyed", () =>
          scrollContainerObserver.disconnect()
        );
      }

      // Recalculate the container dimensions when the wrapper is resized.
      const wrapObserver = newResizeObserver(this.calcDimensions);
      if (wrapObserver) {
        wrapObserver.observe(this.$el);
        // Cleanup when the component is destroyed.
        this.$once("hook:destroyed", () => wrapObserver.disconnect());
      }
    }
  },
  methods: {
    async calcDimensions() {
      // Reset dimensions for correctly recalculating parent dimensions.
      this.width = undefined;
      this.height = undefined;
      await this.$nextTick();

      this.width = `${this.$el.clientWidth}px`;
      this.height = `${this.$el.clientHeight}px`;
    },
    // Check if shadows are needed.
    toggleShadow() {
      const hasHorizontalScrollbar =
        this.$refs.scrollContainer.clientWidth <
        this.$refs.scrollContainer.scrollWidth;
      const hasVerticalScrollbar =
        this.$refs.scrollContainer.clientHeight <
        this.$refs.scrollContainer.scrollHeight;

      const scrolledFromLeft =
        this.$refs.scrollContainer.offsetWidth +
        this.$refs.scrollContainer.scrollLeft;
      const scrolledFromTop =
        this.$refs.scrollContainer.offsetHeight +
        this.$refs.scrollContainer.scrollTop;

      const scrolledToTop = this.$refs.scrollContainer.scrollTop === 0;
      const scrolledToRight =
        scrolledFromLeft >= this.$refs.scrollContainer.scrollWidth;
      const scrolledToBottom =
        scrolledFromTop >= this.$refs.scrollContainer.scrollHeight;
      const scrolledToLeft = this.$refs.scrollContainer.scrollLeft === 0;

      this.shadow.top = hasVerticalScrollbar && !scrolledToTop;
      this.shadow.right = hasHorizontalScrollbar && !scrolledToRight;
      this.shadow.bottom = hasVerticalScrollbar && !scrolledToBottom;
      this.shadow.left = hasHorizontalScrollbar && !scrolledToLeft;
    },
  },
};
</script>

<style lang="scss">
.scroll-shadow {
  &__wrap {
    overflow: hidden;
    position: relative;
  }

  &__scroll-container {
    overflow: auto;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    // //hide scrollbar in chrome, safari and Opera
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  &__shadow-top,
  &__shadow-right,
  &__shadow-bottom,
  &__shadow-left {
    position: absolute;
    border-radius: 6em;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &__shadow-top,
  &__shadow-bottom {
    right: 0;
    left: 0;
    height: 1em;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-image: linear-gradient(rgba(#555, 0.1) 0%, rgba(#fff, 0) 100%);
  }

  &__shadow-top {
    top: 0;
  }

  &__shadow-bottom {
    bottom: 0;
    transform: rotate(180deg);
  }

  &__shadow-right,
  &__shadow-left {
    top: 0;
    bottom: 0;
    width: 1em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: linear-gradient(
      90deg,
      rgba(#555, 0.1) 0%,
      rgba(#fff, 0) 100%
    );
  }

  &__shadow-right {
    right: 0;
    transform: rotate(180deg);
  }

  &__shadow-left {
    left: 0;
  }

  &__scroll-container {
    & .is-active {
      opacity: 1;
    }
  }
}
</style>