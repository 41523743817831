import { getStoreBuilder, BareActionContext } from "vuex-typex";
import { RootState } from "./index";

export interface NewsletterState {
  showNewsletterModal: boolean;
}

const initialState: NewsletterState = {
  showNewsletterModal: false
};

const store = getStoreBuilder<RootState>().module("newsletterModal", initialState);

function getStoreShowNewsletterModal(state: NewsletterState): boolean {
  return state.showNewsletterModal;
}

// getters
const getShowNewsletterModal = store.read(getStoreShowNewsletterModal, "getStoreTranslations");

// mutations
function commitCulture(state: NewsletterState, newValue: boolean): void {
  state.showNewsletterModal = newValue;
}

// actions
async function saveNewsletterModal(context: BareActionContext<NewsletterState, RootState>, payload: NewsletterState): Promise<void> {
  commitCulture(context.state, payload.showNewsletterModal);
}

export const newsletterStore = {
  get showModal(){
    return getShowNewsletterModal();
  },
  saveUserState: store.dispatch(saveNewsletterModal, "saveNewsletterModal"),
};
