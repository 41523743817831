import { Component, Vue, Prop } from "vue-property-decorator";
import SearchModal from "./SearchModal.vue";

@Component({
    components : {
        SearchModal,
    },
})
export default class SearchB2c extends Vue {
    @Prop()
    protected searchPageUrl!: string;
    private modalOpen : boolean = false;
    public translations: any;

    constructor() {
        super();
        this.translations = window.__litium.translation;
    }

    toggleSearchModal() {
        this.modalOpen = !this.modalOpen;

        const body = <HTMLElement>document.querySelector('body');
        const html = <HTMLElement>document.querySelector('html');    
        body.classList.toggle('search-modal-open');
        html.classList.toggle('search-modal-open');
    }
}
