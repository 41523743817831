import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Button, Checkbox, MultiSelect, MultiSelectOption, Search, Select } from "@/imagebank/components";
import { ImageBankFilterModel } from "@/imagebank/model";

@Component({
    components: {
        "v-button": Button,
        // "v-checkbox": Checkbox,
        "v-search": Search,
        // "v-select": Select,
        "v-select-multi": MultiSelect,
    },
})
export default class ImageFilter extends Vue {
    @Prop({ default: undefined })
    filters!: ImageBankFilterModel;
    @Prop()
    bricmateLogo!: string;
    @Prop()
    havenLogo!: string;
    @Prop()
    tapwellLogo!: string;
    @Prop()
    descriptionText!: string;
    @Prop({ default: false })
    loading!: boolean;

    translations: any;

    searchValue: string = "";
    isSearching: boolean = false;
    // checked1: boolean = false;
    // checked2: boolean = true;
    selectedBrands = new Array<string>();
    selectedColors = new Array<string>();
    selectedCategories = new Array<string>();
    selectedImageTypes = new Array<string>();
    filterBrands = new Array<MultiSelectOption>();
    filterColors = new Array<MultiSelectOption>();
    filterCategories = new Array<MultiSelectOption>();
    filterImageTypes = new Array<MultiSelectOption>();

    constructor() {
        super();
        this.translations = window.__litium.translation;
    }

    toggleBrandFilter(brand: string) {
        if (this.selectedBrands.includes(brand)) {
            let index = this.selectedBrands.indexOf(brand);
            this.selectedBrands.splice(index, 1);
        } else {
            this.selectedBrands.push(brand);
        }
    }

    @Watch("filters")
    buildOptions() {
        if (this.filters) {
            this.filterColors = this.filters.colors.map(x => ({ id: x.key, name: x.key }));
            this.filterBrands = this.filters.brands.map(x => ({ id: x.key, name: x.key }));
            this.filterCategories = this.filters.categories.map(x => ({ id: x.key, name: x.key }));
            this.filterImageTypes = this.filters.imageTypes.map(x => ({ id: x.key, name: x.key }));
        }
    }

    @Emit("onSearch")
    onSearch() {
        return {
            search: this.searchValue,
            brands: this.selectedBrands,
            colors: this.selectedColors,
            categories: this.selectedCategories,
            imageTypes: this.selectedImageTypes,
        };
    }

    @Watch("selectedColors")
    @Watch("selectedBrands")
    @Watch("selectedCategories")
    @Watch("selectedImageTypes")
    @Emit("onSearch")
    onSelectMulti(e: string[]) {
        return {
            search: this.searchValue,
            brands: this.selectedBrands,
            colors: this.selectedColors,
            categories: this.selectedCategories,
            imageTypes: this.selectedImageTypes,
        };
    }

    onRemoveSelected(index: number, selectedFilters: string[]) {
        selectedFilters.splice(index, 1);
    }
    tpUnescape(string: string) {
        const e = document.createElement("textarea");
        e.innerHTML = string;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
}
