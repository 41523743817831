import { Component, Emit, Vue } from "vue-property-decorator";
import { imageBankStore } from "@/imagebank/imagebank-state";
import { Button, Checkbox } from "@/imagebank/components";
import { ImageBankImageModel } from "@/imagebank/model";

@Component({
    components: {
        "v-button": Button,
        "v-checkbox": Checkbox,
    },
})
export default class SelectedImagesModal extends Vue {
    translations: any;

    showImages: boolean = false;

    constructor() {
        super();
        this.translations = window.__litium.translation;
    }

    get selectedImages(): ImageBankImageModel[] {
        return imageBankStore.selectedImages;
    }

    get show() {
        return imageBankStore.selectedImages.length > 0;
    }

    @Emit("download")
    onDownload(resolution: "high" | "low") {
        const ids = this.selectedImages.map(x => x.id);

        return {
            resolution,
            ids,
        };
    }

    @Emit("toggle-selection")
    onRemoveSelected(image: ImageBankImageModel) {
        return image;
    }

    @Emit("remove")
    onRemoveAll(images: ImageBankImageModel[]) {
        images.splice(0);
    }

    toggleImages() {
        this.showImages = !this.showImages;
    }

    totalImageSize(images: ImageBankImageModel[]) {
        var totalSize: number = 0;
        images.forEach(image => {
            totalSize += parseInt(image.size);
        });
        return this.convertByteToMB(totalSize).toString();
    }

    convertByteToMB(data) {
        const const_term = 1024;
        return (data / const_term ** 2).toFixed(1) + "mb";
    }
}
