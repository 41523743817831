import { getStoreBuilder, BareActionContext } from "vuex-typex";
import { RootState } from "./index";
import { Cart, AddOrUpdateCartItem, OrderRow, ProductPriceStockRequest, OrderRowCommentsObject } from "@/models/tapwell/Cart";
import { ApiService } from "@/services/tapwell/ApiService";
import { TapwellGroupWebsite } from "@/models/TapwellGroupWebsite";

export interface CheckoutState {
    cart: Cart;
}

const initialState: CheckoutState = {
    cart: {} as Cart,
};

const store = getStoreBuilder<RootState>().module("checkout", initialState);

// private getters
function getStoreCart(state: CheckoutState): Cart {
    return state.cart;
}

// getters
const getStateCart = store.read(getStoreCart, "getStoreCart");

// mutations:

function mutateUpdateRows(state: CheckoutState): void {
    state.cart.orderRows.forEach((row) => {

        // console.log("mutateUpdateRows", row, row.comments);

        if (row.comments) {
            const data: OrderRowCommentsObject = JSON.parse(row.comments);
            row.comment = data.comment;
        }

        //if (!row.inStock) {
        var isBricmateB2B =  window.__currentWebsite === TapwellGroupWebsite.BricmateB2B;
        var checkLitiumInventory = false;
        
        if (isBricmateB2B){
            checkLitiumInventory = true;
        }
        const payload: ProductPriceStockRequest = {
            quantity: row.quantity,
            variantId: row.articleNumber,
            checkLitiumInventory: checkLitiumInventory,
        };
        
        ApiService.getProductDeliveryDate(payload).then((response) => {
            if (response && response.data) {
                row.formattedDateAvailable = response.data.formattedDateAvailable;
                row.dateAvailable = response.data.dateAvailable;
                row.inStock = response.data.inStock;
            }
        });
        //}
    });
}

function mutateSaveState(state: CheckoutState, payload: CheckoutState): Promise<void> {
    return new Promise((resolve, reject) => {
        state.cart = payload.cart;
        mutateUpdateRows(state);
        resolve();
    });
}

function mutateUpdateCart(state: CheckoutState, payload: AddOrUpdateCartItem): Promise<void> {
    return new Promise((resolve, reject) => {
        if (!state.cart.orderRows || state.cart.orderRows.findIndex((row) => row.articleNumber === payload.articleNumber) === -1) {
            ApiService.addToCart(payload).then((response) => {
                if (response && response.data) {
                    state.cart = response.data;
                    mutateUpdateRows(state);
                }
                resolve();
            });
        } else {
            ApiService.updateCart(payload).then((response) => {
                if (response && response.data) {
                    state.cart = response.data;
                    mutateUpdateRows(state);
                }
                resolve();
            });
        }
    });
}

function mutateReloadCart(state: CheckoutState): Promise<void> {
    return new Promise((resolve, reject) => {
        ApiService.getMiniCart().then((response) => {
            if(response && response.data) {        
                state.cart = response.data;
                mutateUpdateRows(state);
            }
            resolve();
        });
    });
}

function mutateImportToCart(state: CheckoutState, payload: File): Promise<void> {
    return new Promise((resolve, reject) => {
        ApiService.importExcelToCheckout(payload).then((response) => {
            if(response && response.data) {
                state.cart = response.data;
                if(state.cart.orderRows!=null)
                {
                    mutateUpdateRows(state);
                }
            }
            resolve();
        });
    });
}

// actions
async function saveState(context: BareActionContext<CheckoutState, RootState>, payload: CheckoutState): Promise<void> {
    return await mutateSaveState(context.state, payload);
}

async function updateState(context: BareActionContext<CheckoutState, RootState>, payload: AddOrUpdateCartItem): Promise<void> {
    return await mutateUpdateCart(context.state, payload);
}

async function reloadState(context: BareActionContext<CheckoutState, RootState>): Promise<void> {
    return await mutateReloadCart(context.state);
}
async function excelImportState(context: BareActionContext<CheckoutState, RootState>, payload: File): Promise<void> {
    return await mutateImportToCart(context.state,payload);
}

// export store
export const CheckoutStore = {
    get cart() { return getStateCart(); },
    saveState: store.dispatch(saveState, "saveState"),
    updateState: store.dispatch(updateState, "updateState"),
    reloadState: store.dispatch(reloadState, "reloadState"),
    excelImportState: store.dispatch(excelImportState, "excelImportState"),
    
};
