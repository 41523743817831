import { localize, extend } from "vee-validate";
import * as Rules from "vee-validate/dist/rules";
import sv from "vee-validate/dist/locale/sv.json";
import fi from "vee-validate/dist/locale/fi.json";
import en from "vee-validate/dist/locale/en.json";

for (const [rule, validation] of Object.entries(Rules)) {
  extend(rule, {
    ...validation
  });
}

localize({
  sv: {
    messages: sv.messages,
    names: {
      address: "adress",
      email: "e-post",
      zipcode: "postnummer",
      city: "ort",
      country: "land",
      recipientname: "mottagarnamn",
      goodslabeling: "märkning",
      firstname: "Förnamn",
      lastname: "Efternamn",
      notificationPhone: "telefon",
      alternatNotificationPhone:"Alternativ telefon",
    },
  },
  fi: {
    messages: fi.messages,
    names: {
      address: "Osolte",
      email: "Vastaanottajan sähköpostiosolte",
      zipcode: "Oikea postinumero",
      city: "Kaupunki",
      country:"Maa",
      recipientname: "Vastaanottajan nimi",
      goodslabeling: "viite on pakollinen",
      firstname: "Etunimi",
      lastname: "Sukunimi",
      notificationPhone: "Puhelinnumero",
      alternatNotificationPhone: "Vastaanottajan puhelinnumero",
    },
  },
  en: {
    messages: en.messages,
    names: {
      address: "address",
      email: "e-mail",
      zipcode: "zipcode",
      city: "city",
      country:"country",
      recipientname: "recipient name",
      goodslabeling: "goods labeling",
      firstname: "Firstname",
      lastname: "Lastname",
      notificationPhone: "phone",
      alternatNotificationPhone: "alternate phone",
    },
  },
});
