import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Checkbox extends Vue {
    @Prop({ default: false })
    value!: boolean;

    @Prop({ default: "small" })
    type!: "large" | "small";

    @Prop({ default: "" })
    label!: string;

    isChecked: boolean = this.value;

    @Watch("value")
    onValueChange() {
        this.isChecked = this.value;
    }

    @Emit("input")
    onClick() {
        this.isChecked = !this.isChecked;
        return this.isChecked;
    }
}
