import {newsletterStore} from "@/store/tapwell/newsletterModal";

export default {
    name: "NewsletterBanner",
    props: ["model"],
    data() {
        return {
            
        };
    },
    computed: {
        translations(){
            return window.__litium.translation;
        }
    },
    methods: {
        handleOnClick(){
            newsletterStore.saveUserState({showNewsletterModal: true});            
        }
    },

};