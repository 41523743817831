import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { ApiService } from "@/imagebank/api";
import { ImageBankImageModel } from "@/imagebank/model";
import ImageTile from "@/imagebank/components/image-tile/ImageTile.vue";
import { imageBankStore } from "@/imagebank/imagebank-state";

@Component({
    components: {
        ImageTile,
    },
})
export default class ImageList extends Vue {

    modalImage!: ImageBankImageModel | null;

    @Prop()
    imagesList!: ImageBankImageModel[];

    constructor() {
        super();
    }

    get selectedImages() {
        return imageBankStore.selectedImages;
    }

    @Emit("setModalImage")
    onShowModal(image: ImageBankImageModel) {
        this.modalImage = image;
    }

    onToggleImage(image: ImageBankImageModel) {
        if (this.selectedImages.find(x => x.articleNumber === image.articleNumber)) {
            imageBankStore.removeSelectedImage(image);
        } else {
            imageBankStore.addSelectedImage(image);
        }
    }

    onDownload(data: { resolution: "high" | "low"; ids: string[] }) {
        ApiService.downloadImages(data.resolution, data.ids);
    }
}