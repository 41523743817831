import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CartView from "./CartView.vue";
import CustomerInformation from "./CustomerInformation.vue"
import { CheckoutModel } from "@/models/tapwell/Checkout";
import { CheckoutStore } from "@/store/tapwell/checkoutstate";

@Component({
    components : {
        CartView,
        CustomerInformation,
    },
})

export default class Checkout extends Vue {
    @Prop()
    protected viewmodel!: CheckoutModel;

    get cart() { return CheckoutStore.cart; }

    constructor() {
        super();
    }
}
