// import { ApiService } from '@/services/bricmate/ApiService';
import { ApiService2 } from '@/services/bricmate/ApiService2';

import { orderCatalog } from "@/models/bricmate/orderCatalog";
import ThankYouModal from "./ThankYouModal.vue";

export default {
    name: 'CatalogForm',
    data() {
        return {
            orderForm: {
                name: '',
                address: '',
                email: '',
                postalCodeAndCity: '',
                mainCatalog: true,
                outSideCatalog: false,
                counterTopCatalog: false,
            } as orderCatalog,
            showThankYouModal: false,
        }
    },
    props: ["viewmodel"],
    components: {
        ThankYouModal
    },
    computed: {
        translations() {
            return window.__litium.translation;
        }
    },
    methods: {
        onSubmit() {
            ApiService2.orderCatalog(this.orderForm).then((response) => {
                if (response && response.status == 200) {
                    this.showThankYouModal = true;
                }
                else {
                    //if send mail fails
                }
            });

        },
        resetForm() {
            this.orderForm = {
                name: '',
                address: '',
                email: '',
                mainCatalog: true,
                outSideCatalog: false,
                counterTopCatalog: false,
            };


            this.$nextTick(() => this.$refs.observer.reset());
        },
        handleCloseModal() {
            this.showThankYouModal = false;
            this.resetForm();
        }
    },
}
